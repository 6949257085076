import { Dialog, DialogActions, DialogTitle, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { GRAY, LIGHT_BLUE, PALE_BLUE_GRAY, PRIMARY_COLOR, RED, THIN_BLUE } from '../../../../theme';
import { Trash } from 'react-feather';
import { getHour } from '../../../../shared/utils/Utils';
import { BodyDialog, HeaderDialog } from '../../../usersList/view/NewAccessModal';
import { Close } from '@mui/icons-material';
import { css } from "@emotion/react"
import { CancelButton, ConfirmButton } from '../../../carrierDetails/views/RequestEditCarrier';
import styled from "@emotion/styled"
import { Stack } from '@mui/system';


const LetterListItem = ({ letter, selected, onSelectLetter, carrierView, onDeleteLetter }) => {
    const analystVeiwCharCount = 56;
    const carrierViewCharCount = 82;

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <ListItemButton
            selected={selected === letter.id}
            onClick={() => onSelectLetter(letter.id)}
            sx={{ borderRight: selected === letter.id ? 'solid #2777B8' : 'none' }}
        >{!carrierView &&
            <ListItemIcon>
                <IconButton onClick={() => setOpenDeleteModal(true)}>
                    <Trash color={RED} size={18} />
                </IconButton>
            </ListItemIcon>}
            <ListItemText
                primary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: selected === letter.id ? '#2777B8' : GRAY, fontWeight: 'bold', display: 'inline', cursor: 'pointer', whiteSpace: '' }}
                        >
                            {letter.title.length > analystVeiwCharCount ? letter.title.slice(0, !carrierView ? analystVeiwCharCount : carrierViewCharCount - 3) + "..." : letter.title}
                        </Typography>
                    </React.Fragment>
                }
                secondary={
                    <React.Fragment>
                        {`${dayjs(letter.createdAt).format('DD/MM/YYYY')} às ${getHour(letter.createdAt)}`}
                    </React.Fragment>
                }
            />
            <LetterDialog open={openDeleteModal}>
                <HeaderDialog>
                    <DialogTitle sx={css` color: white;`}>Apagar Comunicado</DialogTitle>
                    <Close sx={css`cursor: pointer;color: white;margin-right: 16px;`} onClick={() => setOpenDeleteModal(false)} />
                </HeaderDialog>
                <BodyDialog>
                    <Stack direction={'column'} justifyContent={'center'} alignContent={'center'} sx={css`display:flex;justify-content: center;align-items:center;width: 100%;height: 200px;`}>
                        <Typography component={'p'}>Você realmente deseja excluir o comunicado?</Typography>
                        <Typography component={'p'} sx={{ color: GRAY }}>"{letter.title}"</Typography>
                    </Stack>
                </BodyDialog>
                <DialogActions sx={css`display: flow;`}>
                    <ConfirmButton onClick={() => {
                        onDeleteLetter(letter.id);
                        setOpenDeleteModal(false);
                    }}>
                        DELETAR COMUNICADO
                    </ConfirmButton>
                    <CancelButton onClick={() => setOpenDeleteModal(false)}>
                        CANCELAR
                    </CancelButton>
                </DialogActions>
            </LetterDialog>
        </ListItemButton>
    )
}

const LetterDialog = styled(Dialog)`
    .MuiDialogContent-root{   
        overflow-x: hidden;
        @media screen and (max-device-width: 768px){
            margin-right: 2%;
        }
    }
    .MuiPaper-root {
        width: auto;
        min-height: 40%;
        max-width: 40%;
        z-index: 20;
        @media screen and (min-device-width: 769px){
            width: 60%;
        }
    }
`

export default LetterListItem;