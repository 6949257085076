import { Button, Divider, Grid, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { css } from "@emotion/react"
import { GRAY, PRIMARY_COLOR, RED, SECONDARY_COLOR, WHITE } from "../../../theme";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
export const LABLE_REQUEST = {
    //validities attributes
    statusANTT: 'Status ANTT',
    sassmaqValidity: 'Validade SASSMAQ',
    paeValidity: 'Validade P.A.E',
    crExercitoValidity: 'Validade CR Exército',
    ctfIbamaValidity: 'Validade CTF IBAMA',
    litppValidity: 'Validade LITPP',
    rctrcValidity: 'Validade Apólice RCTR-C',
    lmgPolicyRCTRC: 'LMG Apólice RCTR-C',
    lmgPolicyRCDA: 'LMG Apólice RC-DA',
    rcdaValidity: 'Validade Apólice RC-DA',
    civilPoliceLicenseValidity: 'Licença Policia Civil',
    federalPoliceLicenseValidity: 'Licença Polícia Federal',
    //Carrier infos
    companyName: 'Razão social',
    statusSocialContractId: 'Contrato social',
    cnaeTypeId: 'CNAE',
    socialContractFile: 'Arquivo de contrato social',
    emailSendingInvoice: 'E-mail para envio da NF-e Yara (Material)',
    //Bank infos
    bankCountry: "País",
    bankKey: 'Bank key',
    bankAccount: 'Bank account',
    bankAccountAK: 'AK',
    alternativePayee: 'Alternative Payee',
    REGULATORY: 'Regulatório',
    REPRESENTATIVE: 'Representante legal',
    COMERCIAL: 'Comercial',
    CNPJ: 'Cartão CNPJ'
}

const ReviewRequest = ({ request,
    carrierConent,
    statusANTT,
    cnaeOptions,
    goBackToEdit,
    sendRequest,
    regulatoryContactsData,
    legalRepresentativeContactsData,
    comercialContactsData,
    deletedRegulatoryContact,
    deletedRepresentativeContact,
    deletedComercialContact
}) => {

    const navigate = useNavigate();

    const handleRenderListEdited = (re, carr, antt) => {
        const arrayRequest = new Array();

        for (let attrOld in carr) {
            for (let attr in re) {
                if (attrOld === attr && !attr.includes('File')) {
                    if (carr[attrOld] !== re[attr]) {
                        switch (attr) {
                            case "CNPJ":
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual Cartão CNPJ</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>--</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>--</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re.CNPJ.name}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                                break;
                            case "statusANTT":
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr.statusANTT === 0 ? "--" : antt.find(el => el.anttStatusId === carr.statusANTT).anttStatusName}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{antt.find(el => el.anttStatusId === re.statusANTT).anttStatusName}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re.statusANTTFile.name}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                                break;
                            case "cnaeTypeId":
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Alterar para</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] === 0 ? "--" : cnaeOptions.find(el => el.cnaeTypeId === carr[attr]).cnaeTypeName}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{cnaeOptions.find(el => el.cnaeTypeId === re[attr]).cnaeTypeName}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                                break;
                            case "bankAccountAK":
                            case "bankAccount":
                            case "bankCountry":
                            case "bankKey":
                            case "alternativePayee":
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] ? carr[attr] : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr] ? re[attr] : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re.bankFile.name}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                                break;
                            case "rctrcValidity":
                            case "lmgPolicyRCTRC":
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] ? attr.includes('Validity') ? dayjs(carr[attr]).format('DD/MM/YYYY') : carr[attr] : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr] ? attr.includes('Validity') ? dayjs(re[attr]).format('DD/MM/YYYY') : re[attr] : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re.lmgPolicyRCTRCFile.name}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                                break;
                            case "rcdaValidity":
                            case "lmgPolicyRCDA":
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] ? attr.includes('Validity') ? dayjs(carr[attr]).format('DD/MM/YYYY') : carr[attr] : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr] ? attr.includes('Validity') ? dayjs(re[attr]).format('DD/MM/YYYY') : re[attr] : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re.rcdaFile.name}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                                break;
                            case "statusSocialContractId":
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={re[attr + 'File'] ? "20%" : "80%"}>Alterar para</TableCell>
                                                        {re[attr + 'File'] && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] ? carr[attr]+"" === "1"? "Ativo" : "Inativo" : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr] ? re[attr]+"" === "1"? "Ativo" : "Inativo" : '--'}</TableCell>
                                                        {re[attr + 'File'] && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr + 'File'].name}</TableCell>}
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                                break;
                            default:
                                arrayRequest.push(
                                    <ListItem>
                                        <Table>
                                            <TableHead>
                                                <TableHead sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={re[attr + 'File'] ? "58%" : "80%"}>Alterar para</TableCell>
                                                        {re[attr + 'File'] && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={css`background-color: white;`}>
                                                    <TableRow >
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] ? attr.includes('Validity') ? dayjs(carr[attr]).format('DD/MM/YYYY') : carr[attr] : '--'}</TableCell>
                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr] ? attr.includes('Validity') ? dayjs(re[attr]).format('DD/MM/YYYY') : re[attr] : '--'}</TableCell>
                                                        {re[attr + 'File'] && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr + 'File'].name}</TableCell>}
                                                    </TableRow>
                                                </TableBody>
                                            </TableHead>
                                        </Table>
                                    </ListItem>
                                )
                        }
                    }
                } else {
                    if (attrOld === attr && carr[attrOld] !== re[attr] && attr.includes('socialContractFile')) {
                        arrayRequest.push(
                            <ListItem>
                                <Table>
                                    <TableHead>
                                        <TableHead sx={css`background-color: white;`}>
                                            <TableRow >
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={css`background-color: white;`}>
                                            <TableRow >
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{'--'}</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr].name}</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;`}></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </TableHead>
                                </Table>
                            </ListItem>
                        )
                    }
                }
            }
        }

        return arrayRequest;
    }
    /*
        const handleContactsEdit = () => {
            const arrayContacts = new Array();
    
            if (regulatoryContactsData.lenght > 0) {
                if (regulatoryContactsData.find(el => el.edited === true || el.isNewContact === true)) {
                    const localArr = new Array();
                    regulatoryContactsData.forEach(element => {
                        localArr.push(
                             <ListItem>
                                <Table>
                                    <TableHead>
                                        <TableHead sx={css`background-color: white;`}>
                                            <TableRow >
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Alterar para</TableCell>
                                                {re[attr + 'File'] && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"370px"}>Anexo</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={css`background-color: white;`}>
                                            <TableRow >
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] ? attr.includes('Validity') ? dayjs(carr[attr]).format('DD/MM/YYYY') : carr[attr] : '--'}</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr] ? attr.includes('Validity') ? dayjs(re[attr]).format('DD/MM/YYYY') : re[attr] : '--'}</TableCell>
                                                {re[attr + 'File'] && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr + 'File'].name}</TableCell>}
                                            </TableRow>
                                        </TableBody>
                                    </TableHead>
                                </Table>
                            </ListItem>)
                    });
                }
            }
    
            return
        }*/

    const handleRenderContactsRegulatory = (rc) => {
        return rc.map(el => {
            return <ListItem>
                <Table>
                    <TableHead>
                        <TableHead sx={css`background-color: white;`}>
                            <TableRow >
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Nome</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Email</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"370px"}>Telefone</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={css`background-color: white;`}>
                            <TableRow >
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.userName}</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.userEmail}</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.phone ? el.phone : '--'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </TableHead>
                </Table>
            </ListItem>
        })
    }


    const handleRenderContactsRepresentative = (rc) => {
        return rc.map(el => {
            return <ListItem>
                <Table>
                    <TableHead>
                        <TableHead sx={css`background-color: white;`}>
                            <TableRow >
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Nome</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Email</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"370px"}>CPF</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={css`background-color: white;`}>
                            <TableRow >
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.userName}</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.userEmail}</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.personalDocument ? el.personalDocument : '--'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </TableHead>
                </Table>
            </ListItem>
        })
    }

    const handleRenderContactsComercial = (cc) => {
        return cc.map(el => {
            return <ListItem>
                <Table>
                    <TableHead>
                        <TableHead sx={css`background-color: white;`}>
                            <TableRow >
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Nome</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}>Email</TableCell> </TableRow>
                        </TableHead>
                        <TableBody sx={css`background-color: white;`}>
                            <TableRow >
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.userName}</TableCell>
                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{el.userEmail}</TableCell>
                            </TableRow>
                        </TableBody>
                    </TableHead>
                </Table>
            </ListItem>
        })
    }

    return <>
        <Typography variant="subtitle2" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Solicitações de edição</Typography>
        <br />
        <List>
            {handleRenderListEdited(request, carrierConent, statusANTT).map(el => el)}
        </List>
        {regulatoryContactsData.length > 0 && <>
            <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contatos Regulatório -</Typography>
            <List>{handleRenderContactsRegulatory(regulatoryContactsData)}</List>
        </>}
        {legalRepresentativeContactsData.length > 0 && <>
            <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Representantes legal -</Typography>
            <List>{handleRenderContactsRepresentative(legalRepresentativeContactsData)}</List>
        </>}
        {comercialContactsData.length > 0 && <>
            <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contatos comercial -</Typography>
            <List>{handleRenderContactsComercial(comercialContactsData)}</List>
        </>}
        <br />
        <Typography variant="subtitle2" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Solicitações de exclusão</Typography>
        <br />
        {deletedRegulatoryContact.length > 0 && <>
            <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contato regulatório -  <Typography variant="subtitle1" sx={css`font-size: 14px;color:${RED};font-family: YaraMax;display:inline;`}>Os contatos excluídos perderão o acesso ao sistema.</Typography></Typography>
            <List>{handleRenderContactsRegulatory(deletedRegulatoryContact)}</List>
        </>}
        {deletedRepresentativeContact.length > 0 && <>
            <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Representante legal -  <Typography variant="subtitle1" sx={css`font-size: 14px;color:${RED};font-family: YaraMax;display:inline;`}>Os contatos excluídos perderão o acesso ao sistema.</Typography></Typography>
            <List>{handleRenderContactsRepresentative(deletedRepresentativeContact)}</List>
        </>}
        {(deletedComercialContact.length > 0) && <>
            <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contatos comercial -  <Typography variant="subtitle1" sx={css`font-size: 14px;color:${RED};font-family: YaraMax;display:inline;`}>Os contatos excluídos perderão o acesso ao sistema.</Typography></Typography>
            <List>{handleRenderContactsComercial(deletedComercialContact)}</List>
        </>}
        <br />
        <Divider />
        <br />
        <CancelButton onClick={() => navigate(-1)}>
            CANCELAR
        </CancelButton>
        <RollbackButton onClick={goBackToEdit}>
            Voltar ao passo anterior
        </RollbackButton>
        <ConfirmButton onClick={sendRequest}>
            Enviar Solicitação
        </ConfirmButton>
    </>
}

const CancelButton = styled(Button)`
    background-color: white;
    margin: 20;
    color: red;
    border-radius: 10px;
    height: 56px;
    width: 150px;
    font-family: YaraMax;
    &:hover {
        background-color: red;
        color: white
    }
`

const RollbackButton = styled(Button)`
    background-color: white;
    margin: 20;
    color: ${SECONDARY_COLOR};
    border-radius: 10px;
    width: 269px;
    height: 56px;
    right:309px;
    position: absolute;
    font-family: YaraMax;
    &:hover {
        background-color: ${SECONDARY_COLOR};
        color: white
    }
`

const ConfirmButton = styled(Button)`
    background-color: ${PRIMARY_COLOR};
    color: white;
    border-radius: 10px;
    width: 269px;
    height: 56px;
    right:20px;
    position: absolute;
    font-family: YaraMax;
    &.Mui-disabled {
      color: ${WHITE};
      background-color: ${GRAY}
    }
    &:hover {
        background-color: ${WHITE};
        color: ${SECONDARY_COLOR}
    }
`

export default ReviewRequest;