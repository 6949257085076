import React, { useState, useEffect, useCallback } from "react";
import {
    Grid,
    IconButton,
    InputAdornment,
    Button,
    Table,
    Divider,
    TableHead,
    TableRow,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    Select,
    FormControl,
    MenuItem,
    Typography,
} from "@mui/material";
import { Calendar } from "react-feather";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from "date-fns/locale/pt-BR";
import CloseIcon from "@mui/icons-material/Close";
import { GRAY, LIGHT_GRAY, MID_GRAY, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import CarrierDetailsManager from "../CarrierDetailsManager";
import AnttStatusService from "../../../services/AnttStatusService";
import CnaeTypeService from "../../../services/CnaeTypeService";
import IddStatusService from "../../../services/IddStatusService";
import PlantService from "../../../services/PlantService";
import RegionalService from "../../../services/RegionalService";
import StatusContractSignatureService from "../../../services/StatusContractSignatureService";
import StatusSocialContractService from "../../../services/StatusSocialContractService";
import parseISO from "date-fns/parseISO";
import styled from "@emotion/styled";
import { css } from "@emotion/react"
import DataTableAddItem from "../../../shared/components/DataTableAddItem/DataTableAddItem";
import useComercialContact from "../hooks/useComercialContact"
import useRegulatoryContact from "../hooks/useRegulatoryContact"
import useLegalRepresentativeContact from "../hooks/userLegalRepresentative"
import { Modal, notification } from "antd";
import './style.css';
import { useDispatch, useSelector } from "react-redux"
import { updateEmailError, updateTableError } from "../../../store/AddItemTableStore";
import EcovadisService from "../../../services/EcovadisService";


export const configModalConfirm = {
    title: 'Confirmar alterações.',
    okText: 'Confirmar',
    cancelText: 'Cancelar',
    content: 'Esse e-mail já possui usuário cadastrado, deseja vincular como contato?'
};

const CarrierModalEdit = ({ open, tab, onClose, carrier, onConfirm, contacts }) => {

    const [currentTab, setCurrentTab] = useState(0);
    const [txtScoreSerasa, setScoreSerasa] = useState('');
    const [txtLMGApoliceRCTRC, setLMGApoliceRCTRC] = useState('');
    const [txtLMGApoliceRCDA, setLMGApoliceRCDA] = useState('');
    const [selDataConsultaSerasa, setSelDataConsultaSerasa] = useState(null);
    const [selValidadeSASSMAQ, setSelValidadeSASSMAQ] = useState(null);
    const [selValidadePAE, setSelValidadePAE] = useState(null);
    const [selValidadeCRExercito, setSelValidadeCRExercito] = useState(null);
    const [selValidadeCTFIBAMA, setSelValidadeCTFIBAMA] = useState(null);
    const [selValidadeLITPP, setSelValidadeLITPP] = useState(null);
    const [selValidadeApoliceRCTRC, setSelValidadeApoliceRCTRC] = useState(null);
    const [selValidadeApoliceSeguroRCDA, setSelValidadeApoliceSeguroRCDA] = useState(null);
    const [selAnttStatus, setSelAnttStatus] = useState(null);
    const [selIDDStatus, setSelIDDStatus] = useState(null);
    const [txtRazaoSocial, setRazaoSocial] = useState('');
    const [selContratoSocialStatus, setContratoSocialStatus] = useState(null);
    const [selCNAE, setCNAE] = useState(null);
    const [txtContractNumber, setContractNumber] = useState(null);
    const [selContractValidity, setContractValidity] = useState(null);
    const [selStatusContractSignature, setStatusContractSignature] = useState(null);
    const [txtEmailSendingInvoice, setEmailSendingInvoice] = useState(null);
    const [txtRegulatoryContactName, setRegulatoryContactName] = useState(null);
    const [txtRegulatoryContactEmail, setRegulatoryContactEmail] = useState(null);
    const [txtRegulatoryContactPhone, setRegulatoryContactPhone] = useState(null);
    const [txtContactLegalRepresentativeName, setContactLegalRepresentativeName] = useState(null);
    const [txtContactLegalRepresentativeEmail, setContactLegalRepresentativeEmail] = useState(null);
    const [txtContactLegalRepresentativeDocument, setContactLegalRepresentativeDocument] = useState(null);
    const [txtOperationalContactName, setOperationalContactName] = useState(null);
    const [txtOperationalContactEmail, setOperationalContactEmail] = useState(null);
    const [selRegional, setRegional] = useState(null);
    const [plantOptions, setPlantOptions] = useState([]);
    const [regionalsOptions, setRegionalsOptions] = useState([]);
    const [cnaesOptions, setCnaesOptions] = useState([]);
    const [AnttsOptions, setAnttOptions] = useState([]);
    const [IddsOptions, setIddOptions] = useState([]);
    const [StatusSocialContractsOptions, setStatusSocialContractOptions] = useState([]);
    const [StatusContractSignaturesOptions, setStatusContractSignatureOptions] = useState([]);
    const [ecovadis, setEcovadis] = useState(0);
    const [ecovadisScore, setEcovadisScore] = useState();
    const [ecovadisOptions, setEcovadisOptions] = useState([]);
    const [selPlant, setSelPlant] = useState('0');
    const [selBankCountry, setBankCountry] = useState(null);
    const [txtBankKey, setBankKey] = useState(null);
    const [txtBankAccount, setBankAccount] = useState(null);
    const [txtBankAccountAK, setBankAccountAK] = useState(null);
    const [txtAlternativePayee, setAlternativePayee] = useState(null);
    const [tabSelected, setTabSelected] = useState(null);
    const [openPAEDate, setOpenPAEDate] = useState(false);
    const [openCRExercitoDate, setOpenCRExercitoDate] = useState(false);
    const [openIBAMADate, setOpenIBAMADate] = useState(false);
    const [openLITPPDate, setOpenLITPPDate] = useState(false);
    const [sapId, setSapId] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [apiNotification, contextHolder] = notification.useNotification();
    const [modal, contextHolderModal] = Modal.useModal();
    const contactsTableError = useSelector(state => state.addItemTable.tableError)
    const contactsEmailError = useSelector(state => state.addItemTable.emailError)

    //police validity
    const [civilPoliceLicenseValidity, setCivilPoliceLicenseValidity] = useState(null);
    const [federalPoliceLicenseValidity, setFederalPoliceLicenseValidity] = useState(null);

    const dispatch = useDispatch();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        console.log("Verify:::", contactsEmailError)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const isMobile = width <= 769;

    useEffect(async () => {
        if (selRegional) {
            const plants = await RegionalService.getRegionalList();
            const plantsMenu = plants.data.find(el => el.regionalId == selRegional).towers.map((plant) => (
                <MenuItem key={plant.plantId} value={plant.plantId}>
                    {plant.plantName}
                </MenuItem>
            ));
            setPlantOptions(plantsMenu);
        }
    }, [selRegional])

    useEffect(() => {

        if (open && carrier) {
            const loadingLists = async () => {

                const antts = await AnttStatusService.getAnttStatusList();
                const anttsMenu = antts.data.map((antt) => (
                    <MenuItem key={antt.anttStatusId} value={antt.anttStatusId}>
                        {antt.anttStatusName}
                    </MenuItem>
                ));
                setAnttOptions(anttsMenu);

                const idds = await IddStatusService.getIddStatusList();
                const iddsMenu = idds.data.map((idd) => (
                    <MenuItem key={idd.iddStatusId} value={idd.iddStatusId}>
                        {idd.iddStatusName}
                    </MenuItem>
                ));
                setIddOptions(iddsMenu);

                const plants = await RegionalService.getRegionalList();
                const plantsMenu = plants.data.find(el => el.regionalId === carrier.regional?.regionalId)?.towers.map((plant) => (
                    <MenuItem key={plant.plantId} value={plant.plantId}>
                        {plant.plantName}
                    </MenuItem>
                ));
                setPlantOptions(plantsMenu);

                const regionals = await RegionalService.getRegionalList();
                const regionalsMenu = regionals.data.map((regional) => (
                    <MenuItem key={regional.regionalId} value={regional.regionalId}>
                        {regional.regionalName}
                    </MenuItem>
                ));
                setRegionalsOptions(regionalsMenu);

                const cnaes = await CnaeTypeService.getCnaeTypeList();
                const cnaesMenu = cnaes.data.map((cnae) => (
                    <MenuItem key={cnae.cnaeTypeId} value={cnae.cnaeTypeId}>
                        {cnae.cnaeTypeName}
                    </MenuItem>
                ));
                setCnaesOptions(cnaesMenu);

                const statuscontracts = await StatusSocialContractService.getStatusSocialContractList();
                const statuscontractMenu = statuscontracts.data.map((statuscontract) => (
                    <MenuItem key={statuscontract.statusSocialContractId} value={statuscontract.statusSocialContractId}>
                        {statuscontract.statusSocialContractName}
                    </MenuItem>
                ));
                setStatusSocialContractOptions(statuscontractMenu);

                const statussignatures = await StatusContractSignatureService.getStatusContractSignatureList();
                const statussignatureMenu = statussignatures.data.map((statussignature) => (
                    <MenuItem key={statussignature.statusContractSignatureId} value={statussignature.statusContractSignatureId}>
                        {statussignature.statusContractSignatureName}
                    </MenuItem>
                ));
                setStatusContractSignatureOptions(statussignatureMenu);

                const ecovadisStatus = await EcovadisService.getEcovadisStatus();
                const ecovadisMenu = ecovadisStatus.data.map((ecoStatus) => (
                    <MenuItem key={ecoStatus.ecovadisId} value={ecoStatus.ecovadisId}>
                        {ecoStatus.ecovadisName}
                    </MenuItem>
                ));
                setEcovadisOptions(ecovadisMenu);


            };
            loadingLists();

            const dateNotAllowed = ['0001-01-01T00:00:00', '1900-01-01T00:00:00', '9999-12-31T00:00:00'];

            setTabSelected(tab);
            setCurrentTab(tab);

            setScoreSerasa(carrier.carrierSerasaScore);

            if (dateNotAllowed.includes(carrier.consultationSerasa)) {
                setSelDataConsultaSerasa(null);
            }
            else {
                setSelDataConsultaSerasa(carrier.consultationSerasa);
            }

            setSelAnttStatus(carrier?.anttStatus?.anttStatusId);
            setSelIDDStatus(carrier?.iddStatus?.iddStatusId);

            if (dateNotAllowed.includes(carrier.civilPoliceLicenseValidity)) {
                setCivilPoliceLicenseValidity(null);
            } else {
                setCivilPoliceLicenseValidity(carrier.civilPoliceLicenseValidity);
            }

            if (dateNotAllowed.includes(carrier.federalPoliceLicenseValidity)) {
                setFederalPoliceLicenseValidity(null)
            } else {
                setFederalPoliceLicenseValidity(carrier.federalPoliceLicenseValidity)
            }

            if (dateNotAllowed.includes(carrier.sassmaqValidity)) {
                setSelValidadeSASSMAQ(null);
            }
            else {
                setSelValidadeSASSMAQ(carrier.sassmaqValidity);
            }

            if (dateNotAllowed.includes(carrier.paeValidity)) {
                setSelValidadePAE(null);
            }
            else {
                setSelValidadePAE(carrier.paeValidity);
            }

            if (dateNotAllowed.includes(carrier.crExercitoValidity)) {
                setSelValidadeCRExercito(null);
            }
            else {
                setSelValidadeCRExercito(carrier.crExercitoValidity);
            }

            if (dateNotAllowed.includes(carrier.ctfIbamaValidity)) {
                setSelValidadeCTFIBAMA(null);
            }
            else {
                setSelValidadeCTFIBAMA(carrier.ctfIbamaValidity);
            }

            if (dateNotAllowed.includes(carrier.litppValidity)) {
                setSelValidadeLITPP(null);
            }
            else {
                setSelValidadeLITPP(carrier.litppValidity);
            }

            if (dateNotAllowed.includes(carrier.rctrcValidity)) {
                setSelValidadeApoliceRCTRC(null);
            }
            else {
                setSelValidadeApoliceRCTRC(carrier.rctrcValidity);
            }

            if (dateNotAllowed.includes(carrier.rcdaValidity)) {
                setSelValidadeApoliceSeguroRCDA(null);
            }
            else {
                setSelValidadeApoliceSeguroRCDA(carrier.rcdaValidity);
            }

            setLMGApoliceRCTRC(carrier.lmgPolicyRCTRC);
            setLMGApoliceRCDA(carrier.lmgPolicyRCDA);
            setRazaoSocial(carrier.carrierName);
            if (carrier.statusSocialContract != null) {
                setContratoSocialStatus(carrier.statusSocialContract.statusSocialContractId);
            }
            setCNAE(carrier?.cnaeType?.cnaeTypeId);
            setContractNumber(carrier?.contractNumber);

            if (dateNotAllowed.includes(carrier.contractValidity)) {
                setContractValidity(null);
            }
            else {
                setContractValidity(carrier.contractValidity);
            }

            if (carrier.statusContractSignature != null) {
                setStatusContractSignature(carrier.statusContractSignature.statusContractSignatureId);
            }

            setEmailSendingInvoice(carrier.emailSendingInvoice);
            setEcovadisScore(carrier?.ecovadisScore);
            setEcovadis(carrier.ecovadis?.ecovadisId);

            if (carrier.regulatoryContact != null) {
                setRegulatoryContactName(carrier.regulatoryContact.name);
                setRegulatoryContactEmail(carrier.regulatoryContact.email);
                setRegulatoryContactPhone(carrier.regulatoryContact.phone);
            }

            if (carrier.contactLegalRepresentative != null) {
                setContactLegalRepresentativeName(carrier.contactLegalRepresentative.name);
                setContactLegalRepresentativeEmail(carrier.contactLegalRepresentative.email);
                setContactLegalRepresentativeDocument(carrier.contactLegalRepresentative.personalDocument);
            }

            if (carrier.operationalContact != null) {
                setOperationalContactName(carrier.operationalContact.name);
                setOperationalContactEmail(carrier.operationalContact.email);
            }
            if (carrier.regional != null) {
                setRegional(carrier.regional.regionalId);
            }

            if (carrier.sapId !== null) {
                setSapId(carrier.sapId)
            }

            if (carrier.plant != null) {
                setSelPlant(carrier.plant.plantId);
            }

            if (carrier.bankData != null) {
                setBankCountry(carrier.bankData.bankCountry);
                setBankKey(carrier.bankData.bankKey);
                setBankAccount(carrier.bankData.bankAccount);
                setBankAccountAK(carrier.bankData.bankAccountAK);
                setAlternativePayee(carrier.bankData.alternativePayee);
            }
        }
    }, [open, tab, carrier]);

    const handleScoreSerasaChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        const limitedValue = numericValue.slice(0, 3);
        setScoreSerasa(limitedValue);
    };

    const handleLMGApoliceRCTRCChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        setLMGApoliceRCTRC(numericValue);
    };

    const handleLMGApoliceRCDAChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        setLMGApoliceRCDA(numericValue);
    };

    const handleAnttStatusChange = (e) => {
        setSelAnttStatus(e.target.value);
    };

    const handleIDDStatusChange = (e) => {
        setSelIDDStatus(e.target.value);
    };

    const handleDataConsultaSerasaChange = (date) => {
        setSelDataConsultaSerasa(date);
    };

    const onClearSerasaDate = () => {
        setSelDataConsultaSerasa(null);
    }

    const handleCivilPoliceLicenseValidity = (date) => {
        setCivilPoliceLicenseValidity(date);
    }

    const onClearCivilPoliceLicenseValidity = () => {
        setCivilPoliceLicenseValidity(null);
    }

    const handleFederalPoliceLicenseValidity = (date) => {
        setFederalPoliceLicenseValidity(date)
    }

    const onClearFederalPoliceLicenseValidity = () => {
        setFederalPoliceLicenseValidity(null);
    }

    const handleValidadeSASSMAQChange = (date) => {
        setSelValidadeSASSMAQ(date);
    };

    const onClearSASSMAQ = () => {
        setSelValidadeSASSMAQ(null);
    }

    const onClickPAEDate = () => {
        setOpenPAEDate(!openPAEDate);
    }

    const onClearPAEDate = () => {
        setSelValidadePAE(null);
        onClickPAEDate();
    }
    const handleValidadePAEChange = (date) => {
        setSelValidadePAE(date);
        onClickPAEDate();
    };

    const onClickCRExercitoDate = () => {
        setOpenCRExercitoDate(!openCRExercitoDate);
    }

    const handleValidadeCRExercitoChange = (date) => {
        setSelValidadeCRExercito(date);
        onClickCRExercitoDate();
    };

    const onClearCRExercitoDate = () => {
        setSelValidadeCRExercito(null);
        onClickCRExercitoDate();
    }



    const onClickIBAMADate = () => {
        setOpenIBAMADate(!openIBAMADate);
    }

    const handleValidadeCTFIBAMAChange = (date) => {
        setSelValidadeCTFIBAMA(date);
        onClickIBAMADate();
    };

    const onClearIBAMADate = () => {
        setSelValidadeCTFIBAMA(null);
        onClickIBAMADate();
    }

    const onClickLITPPDate = () => {
        setOpenLITPPDate(!openLITPPDate);
    }
    const handleValidadeLITPPChange = (date) => {
        setSelValidadeLITPP(date);
        onClickLITPPDate();
    };

    const onClearLITPPDate = () => {
        setSelValidadeLITPP(null);
        onClickLITPPDate();
    }

    const handleValidadeApoliceRCTRCChange = (date) => {
        setSelValidadeApoliceRCTRC(date);
    };

    const onClearRCTRC = () => {
        setSelValidadeApoliceRCTRC(null);
    }

    const handleValidadeApoliceSeguroRCDAChange = (date) => {
        setSelValidadeApoliceSeguroRCDA(date);
    };

    const onClearRCDA = () => {
        setSelValidadeApoliceSeguroRCDA(null);
    }

    const handleRazaoSocialChange = (e) => {
        setRazaoSocial(e.target.value);
    };

    const handleContratoSocialStatusChange = (e) => {
        setContratoSocialStatus(e.target.value);
    };

    const handleCNAEChange = (e) => {
        setCNAE(e.target.value);
    };

    const handleContractNumberChange = (e) => {
        setContractNumber(e.target.value);
    };

    const handleContractValidityChange = (date) => {
        setContractValidity(date);
    };

    const onClearContract = () => {
        setContractValidity(null);
    }

    const handleStatusContractSignatureChange = (e) => {
        setStatusContractSignature(e.target.value);
    };

    const handleEcovadisOnChange = (e) => {
        setEcovadis(e.target.value);
        setEcovadisScore('');
    }

    const handleEmailSendingInvoiceChange = (e) => {
        setEmailSendingInvoice(e.target.value);
    };

    const handleEcovadisScoreOnChange = (e) => {
        setEcovadisScore(e.target.value)
    }

    const handleRegulatoryContactNameChange = (e) => {
        setRegulatoryContactName(e.target.value);
    };

    const handleRegulatoryContactEmailChange = (e) => {
        setRegulatoryContactEmail(e.target.value);
    };

    const handleRegulatoryContactPhoneChange = (e) => {
        setRegulatoryContactPhone(e.target.value);
    };

    const handleContactLegalRepresentativeNameChange = (e) => {
        setContactLegalRepresentativeName(e.target.value);
    };

    const handleContactLegalRepresentativeEmailChange = (e) => {
        setContactLegalRepresentativeEmail(e.target.value);
    };

    const handleContactLegalRepresentativeDocumentChange = (e) => {
        setContactLegalRepresentativeDocument(e.target.value);
    };

    const handleOperationalContactNameChange = (e) => {
        setOperationalContactName(e.target.value);
    };

    const handleOperationalContactEmailChange = (e) => {
        setOperationalContactEmail(e.target.value);
    };

    const handleRegionalChange = (e) => {
        setRegional(e.target.value);
    };

    const handlePlantChange = (e) => {
        setSelPlant(e.target.value);
    };

    const handleSAPIDChange = (e) => {
        setSapId(e.target.value);
    }

    const handleBankCountryChange = (e) => {
        setBankCountry(e.target.value);
    };

    const handleBankKeyChange = (e) => {
        setBankKey(e.target.value);
    };

    const handleBankAccountChange = (e) => {
        setBankAccount(e.target.value);
    };

    const handleBankAccountAKChange = (e) => {
        setBankAccountAK(e.target.value);
    };

    const handleAlternativePayeeChange = (e) => {
        setAlternativePayee(e.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setTabSelected(false);
        setCurrentTab(newValue);
    };

    const disableFutureDates = (date) => {
        return date > new Date();
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setCarrier(
                carrier.carrierId,
                txtScoreSerasa,
                selDataConsultaSerasa,
                selAnttStatus,
                selIDDStatus,
                selValidadeSASSMAQ,
                selValidadePAE,
                selValidadeCRExercito,
                selValidadeCTFIBAMA,
                selValidadeLITPP,
                selValidadeApoliceRCTRC,
                selValidadeApoliceSeguroRCDA,
                txtLMGApoliceRCTRC,
                txtLMGApoliceRCDA,
                txtRazaoSocial,
                selContratoSocialStatus,
                selCNAE,
                txtContractNumber,
                selContractValidity,
                selStatusContractSignature,
                txtEmailSendingInvoice,
                txtRegulatoryContactName,
                txtRegulatoryContactEmail,
                txtRegulatoryContactPhone,
                txtContactLegalRepresentativeName,
                txtContactLegalRepresentativeEmail,
                txtContactLegalRepresentativeDocument,
                txtOperationalContactName,
                txtOperationalContactEmail,
                selRegional,
                selPlant,
                selBankCountry,
                txtBankKey,
                txtBankAccount,
                txtBankAccountAK,
                txtAlternativePayee,
                regulatoryContactsData,
                legalRepresentativeContactsData,
                comercialContactsData,
                deletedRegulatoryContact,
                deletedRepresentativeContact,
                deletedComercialContact,
                sapId,
                ecovadis,
                ecovadisScore,
                civilPoliceLicenseValidity,
                federalPoliceLicenseValidity
            ).then(carrierId => {
                onConfirm(carrierId);
            });
        }
    };

    const {
        defaultRegulatoryContactsColumns,
        regulatoryContactsData,
        deletedRegulatoryContact,
        handleDeleteRegulatoryContact,
        handleAddRegulatoryContact,
        handleSaveRegulatoryContact
    } = useRegulatoryContact(contacts, open, apiNotification, modal);

    const {
        defaultLegalRepresentativeContactsColumns,
        legalRepresentativeContactsData,
        deletedRepresentativeContact,
        handleDeleteLegalRepresentativeContact,
        handleAddLegalRepresentativeContact,
        handleSaveLegalRepresentativeContact
    } = useLegalRepresentativeContact(contacts, open, apiNotification, modal);

    const {
        defaultComercialContactsColumns,
        comercialContactsData,
        deletedComercialContact,
        handleDeleteComercialContact,
        handleAddComercialContact,
        handleSaveComercialContact
    } = useComercialContact(contacts, open, apiNotification, modal);

    useEffect(() => {
        const contactsEdited = [...regulatoryContactsData, ...legalRepresentativeContactsData, ...comercialContactsData]
        const filtredContacts = contactsEdited.filter(element => element.userEmail === '' || element.userEmail === "contato@transportadora.com" || element.personalDocument === "00000000");

        if (filtredContacts.length >= 1) {
            dispatch(updateEmailError(true));
        } else {
            dispatch(updateEmailError(false));
        }

    }, [regulatoryContactsData, legalRepresentativeContactsData, comercialContactsData]);

    const setCarrier = useCallback(async (carrierId, serasaScore, dataConsultaSerasa, anttStatus, IDDStatus, validadeSASSMAQ,
        validadePAE, validadeCRExercito, validadeCTFIBAMA, validadeLITPP, validadeApoliceRCTRC,
        validadeApoliceSeguroRCDA, LMGApoliceRCTRC, LMGApoliceRCDA, razaoSocial, contratoSocialStatus, CNAE, contractNumber, contractValidity,
        statusContractSignature, emailSendingInvoice, regulatoryContactName, regulatoryContactEmail, regulatoryContactPhone, contactLegalRepresentativeName,
        contactLegalRepresentativeEmail, contactLegalRepresentativeDocument, operationalContactName, operationalContactEmail, regional, plant,
        bankCountry, bankKey, bankAccount, bankAccountAK, alternativePayee, regulatoryContactsDataSave, legalRepresentativeContactsDataSave, comercialContactsDataSave, deletedRegulatoryContactConfirm, legalRepresentativeContactsDataConfirm, deletedComercialContactConfirm, sapId, ecovadis, ecovadisScore,
        civilPoliceLicenseValidity,federalPoliceLicenseValidity) => {
        const carrierEdit = {
            CarrierId: carrierId,
            CarrierName: razaoSocial,
            CarrierSerasaScore: serasaScore,
            CNAETypeId: CNAE,
            ANTTStatusId: anttStatus,
            IDDStatusId: IDDStatus,
            ConsultationSerasa: dataConsultaSerasa,
            CRExercitoValidity: validadeCRExercito ? validadeCRExercito : '',
            SASSMAQValidity: validadeSASSMAQ ? validadeSASSMAQ : '',
            PAEValidity: validadePAE ? validadePAE : '',
            CTFIbamaValidity: validadeCTFIBAMA ? validadeCTFIBAMA : '',
            LITPPValidity: validadeLITPP ? validadeLITPP : '',
            RCTRCValidity: validadeApoliceRCTRC ? validadeApoliceRCTRC : '',
            RCDAValidity: validadeApoliceSeguroRCDA ? validadeApoliceSeguroRCDA : '',
            CompanyName: razaoSocial,
            LMGPolicyRCTRC: LMGApoliceRCTRC,
            LMGPolicyRCDA: LMGApoliceRCDA,
            StatusSocialContractId: contratoSocialStatus,
            ContractNumber: contractNumber,
            ContractValidity: contractValidity ? validadeApoliceSeguroRCDA : '',
            StatusContractSignatureId: statusContractSignature,
            EmailSendingInvoice: emailSendingInvoice,
            RegulatoryContactName: regulatoryContactName,
            RegulatoryContactEmail: regulatoryContactEmail,
            RegulatoryContactPhone: regulatoryContactPhone,
            ContactLegalRepresentativeName: contactLegalRepresentativeName,
            ContactLegalRepresentativeEmail: contactLegalRepresentativeEmail,
            ContactLegalRepresentativeDocument: contactLegalRepresentativeDocument,
            OperationalContactName: operationalContactName,
            OperationalContactEmail: operationalContactEmail,
            RegionalId: regional,
            PlantId: plant,
            BankCountry: bankCountry,
            BankKey: bankKey,
            BankAccount: bankAccount,
            BankAccountAK: bankAccountAK,
            AlternativePayee: alternativePayee,
            SapId: sapId,
            EcovadisId: ecovadis,
            EcovadisScore: ecovadisScore,
            CivilPoliceLicenseValidity: civilPoliceLicenseValidity,
            FederalPoliceLicenseValidity: federalPoliceLicenseValidity
        };

        const carrierNewId = await CarrierDetailsManager.setCarrier(
            carrierEdit
        )

        await CarrierDetailsManager.modalUpdateUsersByCarrier(
            [...regulatoryContactsDataSave, ...legalRepresentativeContactsDataSave, ...comercialContactsDataSave],
            [...deletedRegulatoryContactConfirm, ...legalRepresentativeContactsDataConfirm, ...deletedComercialContactConfirm],
            carrierId
        );
        return carrierNewId;
    }, []);

    const validateForm = () => {
        /*
        if (isActive && reasonToChange.length === 0) {
          setErrorReasonToChange("Preencha o motivo.");
          return false;
        } else if (isActive && (reasonToChange.length < 10 || reasonToChange.length > 100)) {
          setErrorReasonToChange("O motivo deve conter entre 10 e 100 caracteres.");
          return false;
        } else {
          setErrorReasonToChange("");
          return true;
        }
        */
        return true;
    };

    const DatePickerBarClear = ({
        onAccept,
        onCancel,
        onClear,
        _onSetToday,
        className
    }) => {

        return (
            <DialogActions className={className}>
                <Button onClick={onClear}> LIMPAR </Button>
                <Button onClick={onCancel}> CANCELAR </Button>
                <Button onClick={onAccept}> OK </Button>
            </DialogActions>
        );
    };

    const DatePickerBar = ({
        onAccept,
        onCancel,
        _onClear,
        _onSetToday,
        className
    }) => {

        return (
            <DialogActions className={className}>
                <Button onClick={onCancel}> CANCELAR </Button>
                <Button onClick={onAccept}> OK </Button>
            </DialogActions>
        );
    };

    return (
        <CarrierDialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
        >
            {contextHolder}
            {contextHolderModal}
            <ModalHeader>
                <ModalTitle>
                    Editar Transportadora
                </ModalTitle>
                <CloseModalButton onClick={onClose} />
            </ModalHeader>
            <ModalContent>
                <ModalTabs
                    value={tabSelected ? tabSelected : currentTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <ModalTabTitle label="Homologação e Validades" />
                    <ModalTabTitle label="Dados cadastrais" />
                    <ModalTabTitle label="Contato regulatório e representante legal" />
                    <ModalTabTitle label="Contato comercial e Dados Yara" />
                    <ModalTabTitle label="Dados bancários" />
                </ModalTabs>
                {(currentTab === 0 || tabSelected === 0) && (
                    <ModalFormBody>
                        <Divider sx={css`margin-top: 15px;`} />
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <ModalTableForm item xs={2} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Tipo de Transporte
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"

                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={1}
                                            >
                                                <MenuItem value="1" variant="outlined" size="small" selected="true" sx={css`font-size: 13.5;`}>Rodoviário</MenuItem>
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Score Serasa
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtScoreSerasa}
                                            variant="outlined"
                                            size="small"

                                            onChange={handleScoreSerasaChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Data consulta Serasa
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selDataConsultaSerasa)}
                                                onChange={handleDataConsultaSerasaChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                cancelLabel="CANCELAR"
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearSerasaDate, clearLabel: "LIMPAR" },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                shouldDisableDate={disableFutureDates}
                                                InputLabelProps={{ shrink: false }}
                                                sx={css`width: 100%;`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Status ANTT
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selAnttStatus}
                                                onChange={handleAnttStatusChange}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {AnttsOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                        </ModalFormContainer>
                        <Divider sx={css`margin-top: 15px;`} />
                        <ModalFormContainer container >
                            <Table>
                                <ModalTableHead >
                                    <TableRow>
                                        Validades
                                    </TableRow>
                                </ModalTableHead>
                            </Table>
                        </ModalFormContainer>
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }} >
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Status IDD
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            sx={css`width: 100%;`}
                                        >
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selIDDStatus}
                                                onChange={handleIDDStatusChange}
                                                id="statusIDD"
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {IddsOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Validade SASSMAQ
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selValidadeSASSMAQ)}
                                                onChange={handleValidadeSASSMAQChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                cancelLabel="CANCELAR"
                                                InputLabelProps={{ shrink: false }}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearSASSMAQ, clearLabel: "LIMPAR" },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                sx={css`width: 100%;`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}

                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Validade P.A.E
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selValidadePAE)}
                                                onChange={handleValidadePAEChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                disable
                                                size="small"
                                                cancelLabel="CANCELAR"
                                                InputLabelProps={{ shrink: false }}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearPAEDate },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                sx={css`width: 100%;`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}


                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Validade CR Exército
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selValidadeCRExercito)}
                                                onChange={handleValidadeCRExercitoChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                disable
                                                sx={css`width: 100%;`}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearCRExercitoDate },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                InputLabelProps={{ shrink: false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onClose={onClickCRExercitoDate}


                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Validade CTF IBAMA
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selValidadeCTFIBAMA)}
                                                onChange={handleValidadeCTFIBAMAChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                disable
                                                sx={css`width: 100%;`}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearIBAMADate },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                InputLabelProps={{ shrink: false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onClose={onClickIBAMADate}


                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Validade LITPP
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selValidadeLITPP)}
                                                onChange={handleValidadeLITPPChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                disable
                                                sx={css`width: 100%;`}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearLITPPDate, clearLabel: "LIMPAR" },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                InputLabelProps={{ shrink: false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onClose={onClickLITPPDate}


                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Validade Apólice RCTR-C
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selValidadeApoliceRCTRC)}
                                                onChange={handleValidadeApoliceRCTRCChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                cancelLabel="CANCELAR"
                                                InputLabelProps={{ shrink: false }}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearRCTRC, clearLabel: "LIMPAR" },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                sx={css`width: 100%;`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}


                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Validade Apólice RC-DA
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(selValidadeApoliceSeguroRCDA)}
                                                onChange={handleValidadeApoliceSeguroRCDAChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                cancelLabel="CANCELAR"
                                                InputLabelProps={{ shrink: false }}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearRCDA, clearLabel: "LIMPAR" },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                sx={css`width: 100%;`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}


                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            LMG Apólice RCTR-C
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtLMGApoliceRCTRC}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleLMGApoliceRCTRCChange}

                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            LMG Apólice RC-DA
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtLMGApoliceRCDA}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleLMGApoliceRCDAChange}

                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Licença Policia Civil
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(civilPoliceLicenseValidity)}
                                                onChange={handleCivilPoliceLicenseValidity}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                cancelLabel="CANCELAR"
                                                InputLabelProps={{ shrink: false }}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearCivilPoliceLicenseValidity, clearLabel: "LIMPAR" },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                sx={css`width: 100%;`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}


                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Licença Polícia Federal
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <ModalDatePicker
                                                defaultValue={parseISO(federalPoliceLicenseValidity)}
                                                onChange={handleFederalPoliceLicenseValidity}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                cancelLabel="CANCELAR"
                                                InputLabelProps={{ shrink: false }}
                                                slots={{
                                                    actionBar: isMobile ? DatePickerBarClear : undefined
                                                }}
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearFederalPoliceLicenseValidity, clearLabel: "LIMPAR" },
                                                    actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                                }}
                                                sx={css`width: 100%;`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="p-0" size="large">
                                                                <Calendar size={14} color={SECONDARY_COLOR} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            ></ModalDatePicker>
                                        </LocalizationProvider>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                        </ModalFormContainer>
                    </ModalFormBody>
                )}
                {(currentTab === 1 || tabSelected === 1) && (
                    /* ABA2 */
                    <ModalFormBody>
                        <Divider sx={css`margin-top: 15px;`} />
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <ModalTableForm item xs={5} sm={5} md={5}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Razão social
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtRazaoSocial}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleRazaoSocialChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={2} sm={2} md={2}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Contrato social
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selContratoSocialStatus}
                                                onChange={handleContratoSocialStatusChange}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {StatusSocialContractsOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={5} sm={5} md={5}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            CNAE
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selCNAE}
                                                onChange={handleCNAEChange}
                                                sx={css`white-space: normal;`}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {cnaesOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Número do contrato
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtContractNumber}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleContractNumberChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Status de assinatura do contrato
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selStatusContractSignature}
                                                onChange={handleStatusContractSignatureChange}
                                                sx={css`white-space: normal;`}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {StatusContractSignaturesOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={6} sm={6} md={6}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            E-mail para envio da NF-e Yara (Material)
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtEmailSendingInvoice}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleEmailSendingInvoiceChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            ECOVADIS
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={ecovadis}
                                                onChange={handleEcovadisOnChange}
                                                sx={css`white-space: normal;`}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {ecovadisOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            ECOVADIS - Media
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <TextField
                                            value={ecovadisScore}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{ inputProps: { max: 100 } }}
                                            sx={css`background-color: ${ecovadis !== 1 ? LIGHT_GRAY : "#FFF"}};`}
                                            size="small"
                                            disabled={ecovadis !== 1}
                                            onChange={handleEcovadisScoreOnChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                        </ModalFormContainer>
                    </ModalFormBody>
                )}
                {(currentTab === 2 || tabSelected === 2) && (
                    <ModalFormBody>
                        <Divider sx={css`margin-top: 15px;`} />
                        <ModalFormContainer container >
                            <ModalTitleDivider >Contato regulário</ModalTitleDivider>
                        </ModalFormContainer>
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <ModalTableForm item xs={4} sm={8} md={12}>
                                <DataTableAddItem
                                    titleButton="Adicionar Contato regulátorio"
                                    columns={defaultRegulatoryContactsColumns}
                                    dataSource={regulatoryContactsData}
                                    handleAdd={handleAddRegulatoryContact}
                                    handleSave={handleSaveRegulatoryContact}
                                    handleDelete={handleDeleteRegulatoryContact}
                                />
                            </ModalTableForm>
                        </ModalFormContainer>
                        <Divider sx={css`margin-top: 50px;`} />
                        <ModalFormContainer container >
                            <ModalTitleDivider>Representante legal</ModalTitleDivider>
                        </ModalFormContainer>
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <ModalTableForm item xs={4} sm={8} md={12}>
                                <DataTableAddItem
                                    titleButton="Adicionar Representante legal"
                                    columns={defaultLegalRepresentativeContactsColumns}
                                    dataSource={legalRepresentativeContactsData}
                                    handleAdd={handleAddLegalRepresentativeContact}
                                    handleSave={handleSaveLegalRepresentativeContact}
                                    handleDelete={handleDeleteLegalRepresentativeContact}
                                />
                            </ModalTableForm>
                        </ModalFormContainer>
                    </ModalFormBody>
                )}
                {(currentTab === 3 || tabSelected === 3) && (
                    <ModalFormBody>
                        <Divider sx={css`margin-top: 15px;`} />
                        <ModalFormContainer container >
                            <ModalTitleDivider>Contato comercial</ModalTitleDivider>
                        </ModalFormContainer>
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <ModalTableForm item xs={6} sm={6} md={12}>
                                <DataTableAddItem
                                    titleButton="Adicionar Contato Comercial"
                                    columns={defaultComercialContactsColumns}
                                    dataSource={comercialContactsData}
                                    handleAdd={handleAddComercialContact}
                                    handleSave={handleSaveComercialContact}
                                    handleDelete={handleDeleteComercialContact}
                                />
                            </ModalTableForm>
                        </ModalFormContainer>
                        <Divider sx={css`margin-top: 15px;`} />
                        <ModalFormContainer container >
                            <ModalTitleDivider>Dados Yara</ModalTitleDivider>
                        </ModalFormContainer>
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Regional
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selRegional}
                                                onChange={handleRegionalChange}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {regionalsOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Torre
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selPlant}
                                                onChange={handlePlantChange}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                {plantOptions}
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            ID SAP
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <TextField value={sapId} onChange={handleSAPIDChange} size="small" sx={css`& .MuiOutlinedInput-root{background: white;}`} />
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                        </ModalFormContainer>
                    </ModalFormBody>
                )}
                {(currentTab === 4 || tabSelected === 4) && (
                    <ModalFormBody>
                        <Divider sx={css`margin-top: 15px;`} />
                        <ModalFormContainer container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            País
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <FormControl fullWidth
                                            variant="outlined"
                                            size="small">
                                            <ModalSelectForm
                                                variant="outlined"
                                                size="small"
                                                value={selBankCountry}
                                                onChange={handleBankCountryChange}
                                            >
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                <MenuItem value="BR" sx={css`font-size: 13.5;`}>BR</MenuItem>
                                            </ModalSelectForm>
                                        </FormControl>
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Bank key
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtBankKey}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleBankKeyChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Bank account
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtBankAccount}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleBankAccountChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            AK
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtBankAccountAK}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleBankAccountAKChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                            <ModalTableForm item xs={3} sm={3} md={3}>
                                <Table>
                                    <ModalTableHead >
                                        <TableRow>
                                            Alternative Payee
                                        </TableRow>
                                    </ModalTableHead>
                                </Table>
                                <Table>
                                    <TableRow>
                                        <ModalTextField
                                            value={txtAlternativePayee}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleAlternativePayeeChange}
                                        />
                                    </TableRow>
                                </Table>
                            </ModalTableForm>
                        </ModalFormContainer>
                    </ModalFormBody>
                )}
            </ModalContent>
            <DialogActions sx={css`justify-content: space-between;`}>
                <ModalCancelButton onClick={onClose}>
                    CANCELAR
                </ModalCancelButton>
                <ModalConfirmButton
                    onClick={handleSubmit}
                    disabled={contactsEmailError}
                >
                    CONFIRMAR
                </ModalConfirmButton>
            </DialogActions>
        </CarrierDialog>
    );
};

export const ModalDatePicker = styled(DatePicker)`
    background-color: #FFFFFF;
    & .MuiFormControl-root{
        width: 100%;
        min-width: 140px;
    }

    & .MuiInputBase-input {
        font-size: 12px;
        font-family: YaraMax;
        cursor: pointer;
        height: 20px;
        width: 100%;
        max-width: 260px;
        padding: 10.5px 14px;
        margin-right: -50px;
    }
    & .MuiInput-underline:before {
        display: none;
    }
    & .MuiInput-underline:after {
        display: none;
    }
    &:hover{
        border-color: ${SECONDARY_COLOR};
    }
    & .MuiInputLabel-formControl {
        top: -5px;
    }

    & .MuiOutlinedInput-adornedEnd {
        paddingRight: 7px
    }
`

const CarrierDialog = styled(Dialog)`
    .MuiDialogContent-root{   
        overflow-x: hidden;
        @media screen and (max-device-width: 768px){
            margin-right: 2%;
        }
    }
    .MuiPaper-root {
        width: auto;
        min-height: 70%;
        max-width: 100%;
        z-index: 20;
        @media screen and (min-device-width: 769px){
            width: 60%;
        }
    }
`

const ModalHeader = styled(Grid)`
    background-color: ${PRIMARY_COLOR};
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const ModalTitle = styled(DialogTitle)`
    color: white;
    
`

const CloseModalButton = styled(CloseIcon)`
    cursor: pointer;
    color: WHITE;
    margin-right: 2%;
`

const ModalContent = styled(DialogContent)`
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    background-color: #f9f8f8;
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: #CCC;
`
const ModalTabs = styled(Tabs)`
    border-bottom-color: #f9f8f8;
    border-bottom-style: inset;
    border-bottom-width: thin;
    background-color: white;
`

const ModalTabTitle = styled(Tab)`
    font-size: 13px;
    text-transform: initial !important;
`
const ModalFormBody = styled.div`
    font-size: 12px;
    background-color: #f9f8f8;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 24px;
    padding-right: 24px;
`
const ModalFormContainer = styled(Grid)`
    margin-left: 0px;
    margin-right: 0px;
`
const ModalTitleDivider = styled(Typography)`
    margin-top: 5px;
    font-size: 10px;
    color: gray;
`
const ModalTableForm = styled(Grid)`
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`

export const ModalSelectForm = styled(Select)`
    font-size: 13.5px;
    background-color: #FFFFFF;
    #statusIDD{
        min-width: 100%
    }
    & .MuiFormControl-root {
        width: 100% !important;
        min-width: 100%;
    }

    & .MuiInputBase-input{
        height: 100%;
        width: 100%;
        padding: 10.5px 14px;
        white-space: normal;
    }
`
export const ModalTextField = styled(TextField)`
    font-size: 13.5px,
    height: 41px;
    width: 100%;
    
    & .MuiInputBase-input {
        font-size: 13.5px;
        height: 41px;
        white-space: normal;
    }
    & .MuiInputBase-root {
        height: 41px;
        background-color: #FFFFFF;
        white-space: normal;
    }
`

const ModalTableHead = styled(TableHead)`
    height: 30px;
    display: table-cell;
    vertical-align: bottom;
`

const ModalConfirmButton = styled(Button)`
    background-color: ${PRIMARY_COLOR};
    color: white;
    border-radius: 10;
    width: 150;
    margin: 20;
    &:hover {
        background-color: gray;
        color: white
    }
    &.Mui-disabled {
      color: white;
      background-color: ${MID_GRAY};
    }
`

const ModalCancelButton = styled(Button)`
    background-color: white;
    margin: 20;
    color: red;
    border-radius: 10;
    width: 150;
    &:hover {
        background-color: red;
        color: white
    }
`

export default CarrierModalEdit;
