import axios from "../axiosInstance";


class NewsLetterService {
    async getNewsletterList() {
        return await axios.get(`newsletter`);
    }

    async sendNewLetter(title, content, emailCheck) {
        const config = { headers: {'Content-Type': 'application/json'} };
        console.log("EMAILCHEK::::",emailCheck)
        return await axios.post(`newsletter?title=${title}&SendEmail=${emailCheck ? 1 : 0}`, content, config)
    }

    async sendAttache(id, file){
        return await axios.post(`newsletter/attache?newsletter=${id}`, file.file, 
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
    }

    async deleteLetter(id){
        return await axios.delete(`newsletter/contact?newsletter=${id}`);
    }

    async downloadFile(id){
        return await axios.get(`newsletter/attache?newsletterid=${id}`);
    }

    async testeEmail(title, content, email){
        const config = { headers: {'Content-Type': 'application/json'} };
        return await axios.post(`newsletter/emailtest?title=${title}&recipient=${email}`, content, config)
    }
}

export default new NewsLetterService();