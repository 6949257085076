
export const SET_NORMALCARRIER_SELECTED = "setSelected/SELECT_NORMAL_CARRIER"
export const SET_SOLICITATIONLIST_SELECTED = "setSelected/SLECT_SOLICITATION_LIST"
export const SET_USERCONTROLERLIST_SELECTED = "setSelected/SLECT_USER_CONTROLER_LIST"
export const SET_NEWSLETTERLIST_SELECTED = "setSelected/SLECT_NEWSLETTER_LIST"

const INITIAL_STATE = {
    normalCarrierList: false,
    solicitationsList: false,
    userControlerList: false,
    newsLetterList: false
}

export function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_NORMALCARRIER_SELECTED:
            return {
                ...state,
                normalCarrierList: action.payload,
                solicitationsList: false,
                userControlerList: false,
                newsLetterList: false
            };
        case SET_SOLICITATIONLIST_SELECTED:
            return {
                ...state,
                normalCarrierList: false,
                solicitationsList: action.payload,
                userControlerList: false,
                newsLetterList: false
            };
        case SET_USERCONTROLERLIST_SELECTED:
            return {
                ...state,
                normalCarrierList: false,
                solicitationsList: false,
                userControlerList: action.payload,
                newsLetterList: false
            };
        case SET_NEWSLETTERLIST_SELECTED:
            return {
                ...state,
                normalCarrierList: false,
                solicitationsList: false,
                userControlerList: false,
                newsLetterList: action.payload
            };
        default:
            return state;
    }
}

export const updateBarSelectedStore = (type) =>{
    return {
        type: type,
        payload: true
      };
}