import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userStore } from "./UserStore";
import { reducer as authStore } from "./AuthStore";
import { reducer as windowSizeStore } from "./WindowSizeStore";
import { reducer as routerStore } from "./RouterStore";
import { reducer as notificationStore } from "./NotificationStore";
import { reducer as managementPageStore } from "./ManagementPageStore";
import {reducer as filterListCarrireStore}  from "./FilterListCarrierStore";
import {reducer as FilterListUserStore } from "./FilterListUsersStore";
import {reducer as FilterListSolicitationsStore } from "./FilterListSolicitationsStore";
import {reducer as SolicitationStore} from './SolicitationStore'
import {reducer as AddItemTableStore} from './AddItemTableStore'
import {reducer as SideBarSelectedStore} from './SideBarSelectedStore';

const reducers = combineReducers({
  user: userStore,
  authentication: authStore,
  window: windowSizeStore,
  router: routerStore,
  notification: notificationStore,
  managementPage: managementPageStore,
  listCarrierFilter: filterListCarrireStore,
  listUsersFilter: FilterListUserStore,
  listSolicitationsFilter: FilterListSolicitationsStore,
  solicitation: SolicitationStore,
  addItemTable: AddItemTableStore,
  sideBarSelectedStore: SideBarSelectedStore
});

export default reducers;
