import { Grid, Modal, Typography } from "@mui/material"
import { GRAY, PRIMARY_COLOR } from "../../../../theme";
import dayjs from "dayjs";
import { Image } from "react-feather";
import pdfIcon from "../../../../shared/img/pdfIcon.png";
import { Box, Stack } from "@mui/system";
import NewsLetterService from "../../../../services/NewsLetterService";
import { saveAs } from 'file-saver';
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Spin } from "antd";

function contains(target, pattern) {
    var value = 0;
    pattern.forEach(function (word) {
        value = value + target.includes(word);
    });
    return (value === 1)
}


const LetterContent = ({ letter }) => {

    const [doc, setDoc] = useState();
    const [doc64, setDoc64] = useState()
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        async function load() {
            if (letter?.file) {
                setLoad(true);
                await NewsLetterService.downloadFile(letter.id).then(resp => {
                    const base64Response = resp.data.fileContents;
                    setDoc64(base64Response)
                    const binary = atob(base64Response.replace(/\s/g, ''));
                    const len = binary.length;
                    const buffer = new ArrayBuffer(len);
                    const view = new Uint8Array(buffer);
                    for (let i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i);
                    }

                    const blob = new Blob([view], { type: 'application/octet-stream' });
                    setDoc(blob)
                }).finally(() => {
                    setLoad(false);
                })
            }
        }
        load();
    }, [letter])

    const handleDownload = async () => {
        saveAs(doc, letter.file);
    }

    const handleClickImg = () =>{
        setOpen(true);
    }
    const handleClose = () =>{
        setOpen(false);
    }

    return <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} >
        <Grid item md={12} sm={8} xs={4}>
            <Typography component={'h5'} sx={{ fontWeight: 'bold', color: PRIMARY_COLOR }}>{letter?.title}</Typography>
        </Grid>
        <Grid item md={12} sm={8} xs={4} sx={{ marginBottom: '25px;' }}>
            <Typography
                component="span"
                variant="body2"
                sx={{ color: GRAY, display: 'inline', cursor: 'pointer' }}
            >{`${dayjs(letter?.createdAt).format('DD/MM/YYYY')} às ${dayjs(letter?.createdAt).hour()}:${dayjs(letter?.createdAt).minute()} - ${letter?.author} - Yara Brasil`}</Typography>
        </Grid>
        <Grid item md={12} sm={8} xs={4}>
            {letter?.file && !contains(letter?.file, ['png', 'jpg', 'jpeg']) ? <Typography onClick={handleDownload} component={'span'} sx={{ fontWeight: 'bold', color: PRIMARY_COLOR, textDecoration: 'underline', cursor: 'pointer' }}> <Stack direction={'row'}>{letter?.file.includes('.pdf') ? <img src={pdfIcon} /> : <Image />}{letter?.file}</Stack></Typography> : ''}
            <br />
            <Typography component={'span'}> <div dangerouslySetInnerHTML={{ __html: letter?.content }} /></Typography>
            {(letter?.file && contains(letter?.file, ['png', 'jpg', 'jpeg'])) && <Box maxWidth={500} maxHeight={100}>
                <Spin spinning={load}>
                    <img onClick={handleClickImg} style={{ maxHeight: '300px' }} src={`data:image/png;base64,${doc64}`} />
                </Spin>
            </Box>}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <img style={style} onClick={handleClickImg} src={`data:image/png;base64,${doc64}`} />
            </Modal>
        </Grid>
    </Grid>
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

export default LetterContent;