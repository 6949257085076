import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Grid,
    Tab,
    Typography,
    Switch,
    TableCell,
    Table,
    TableRow,
    TableContainer,
    TableHead,
    TableBody,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    Stack,
    Button,
} from "@mui/material";
import { Edit, AlertTriangle, Flag, CheckSquare, XCircle } from "react-feather";
import { FIXED_DRAWER_WIDTH, FIXED_HEADER_HEIGHT, routes } from "../../../shared/utils/Constants";
import { Layout } from "../../layout";
import {
    DARK_GRAY,
    GRAY,
    LIGHT_GRAY,
    RED,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    YELLOW
} from "../../../theme";
import { ArrowLeft } from "react-feather";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getDate, getHour } from "../../../shared/utils/Utils";
import { accessType, carrierStatus, environment } from "../../../shared/utils/enum";
import CarrierData from "./CarrierData";
import CarrierDetailsManager from "../CarrierDetailsManager";
import Loading from "../../../shared/components/Loading";
import CarrierModalSetStatus from "./CarrierModalSetStatus";
import CarrierModalEdit from "./CarrierModalEdit";
import { ReactReduxContext, useDispatch } from 'react-redux'
import styled from "@emotion/styled";
import { css } from "@emotion/react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SolicitationService from "../../../services/SolicitationService";
import { LABLE_REQUEST } from "./ReviewRequest";
import { Modal, notification } from "antd";
import HomologationModal from "./HomologationModal";
import { updateTableError } from "../../../store/AddItemTableStore";
import CnaeTypeService from "../../../services/CnaeTypeService";
import { configureEnvironment } from "../../../config/env";
import dayjs from "dayjs";
import CarrierDocument from "./CarrierDocuments";
import TextArea from "antd/es/input/TextArea";
import DocumnetService from "../../../services/DocumnetService";

const CarrierDetails = props => {
    // const {
    //     location: {
    //         state: { carrierId }
    //     }
    // } = props;

    const location = useLocation();

    const { store } = useContext(ReactReduxContext)
    const [canEdit, setCanEdit] = useState(false);
    const [carrierView, setCarrierView] = useState(false);

    useEffect(() => {
        const { user: {
            profile
        } } = store.getState();

        if (profile === accessType.logisticsAnalyst) {
            setCanEdit(true);
        }

        if (profile === accessType.carrier || profile === accessType.hiring) {
            setCarrierView(true);
        }
    }, [store])

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [carrier, setCarrier] = useState(null);
    const [isActive, setActive] = useState(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isDialogStatusOpen, setDialogStatusOpen] = useState(false);
    const [tabActive, setTabActive] = useState(0);
    const [tabSelected, setTabSelected] = useState(null);
    const [openDetails, setOpenDetails] = useState(Array(carrier?.historyLogs?.length).fill(false));
    const [contactUsers, setContactUsers] = useState();
    const [pendingSolicitations, setPendingSolicitation] = useState();
    const [solicitationList, setSolicitationList] = useState();
    const [solicitationDocsList, setsolicitationDocsList] = useState();


    const [modal, contextHolderModal] = Modal.useModal();
    const [apiNotification, contextHolder] = notification.useNotification();

    const scrollRef = useRef(null);

    const scrollToTop = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0);
        }
    };

    const handleSwitchClick = () => {
        setDialogStatusOpen(true);
    };

    const handleOpenTab = (tabNumber) => {
        setDialogOpen(true);
        setTabActive(tabNumber);
    };

    const handleDialogStatusCancel = () => {
        setDialogStatusOpen(false);
    };

    const handleDialogEditCancel = () => {
        setTabActive(0);
        setDialogOpen(false);
    };

    const handleDialogStatusConfirm = carrierNewId => {
        setDialogStatusOpen(false);
        getCarrierDetails(carrierNewId);
    };

    const handleDialogEditConfirm = carrierNewId => {
        setTabActive(0);
        setDialogOpen(false);
        getCarrierDetails(carrierNewId);
        dispatch(updateTableError(false));
    }

    const getCarrierDetails = useCallback(async id => {
        setLoading(true);
        const data = await CarrierDetailsManager.getCarrierDetails(id);
        if (data) {
            setActive(data.carrierStatus?.carrierStatusId === carrierStatus.active.id);
            setCarrier(data);
        }
        setOpenDetails(Array(data?.historyLogs?.length).fill(false));
        await CarrierDetailsManager.getUsersByCarrier(id).then((resp) => {
            setContactUsers(resp);
        })

        await SolicitationService.getPendingSolicitation(id).then(resp => {
            setPendingSolicitation(resp.data)
        })

        await SolicitationService.getSolicitationByCarrier(id).then(resp => {
            setSolicitationList(resp.data);
        });

        await DocumnetService.getDocs(id).then(resp => {
            setsolicitationDocsList(resp.data)
        });

        scrollToTop();
        setLoading(false);
    }, []);

    useEffect(() => {
        getCarrierDetails(location.state.carrierId);
    }, [location.state.carrierId, getCarrierDetails]);

    const handleTabChange = (event, newValue) => {
        setOpenDetails(Array(carrier?.historyLogs?.length).fill(false));
        setTabSelected(newValue);
    };

    const handleChevronClick = (index) => {
        const updatedOpenDetails = [...openDetails];
        updatedOpenDetails[index] = !updatedOpenDetails[index];
        setOpenDetails(updatedOpenDetails);
    };

    const handleCancelSolicitation = async (id) => {
        const confrimModal = await modal.confirm({
            title: 'Cancelar solicitação',
            okText: 'Sim! excluir solicitação',
            cancelText: 'Cancelar',
            content: 'Você realmente deseja cancelar a solicitação de edição?'
        });

        if (
            !confrimModal
        ) {
            return;
        }
        SolicitationService.carrierCancelSolicitation(id).finally(() => {
            apiNotification['success']({
                message: 'Solicitação cancelada!',
                placement: 'topRight',
                duration: 5
            });

            getCarrierDetails(location.state.carrierId);
        })
    }

    const [modalHomologation, setModalHomologation] = useState(false);

    const onClosModalHomologation = () => setModalHomologation(false);

    const onClickHomologationRequirements = () => setModalHomologation(true);


    const [cnaeOptions, setCnaeOptions] = useState();

    const handleOptionsCNAE = async () => {
        await CnaeTypeService.getCnaeTypeList().then(response => {
            setCnaeOptions(response.data)
        });
    }

    const handleSolicitationCell = (obj) => {
        if (obj.oldValue !== null)
            return obj.oldValue?.length > 0 && obj.variable.includes('Validity') && !isNaN(Date.parse(obj.oldValue)) ?
                dayjs(obj.oldValue).format('DD/MM/YYYY') :
                obj.variable.includes("statusANTT") || obj.variable.includes("statusSocialContractId") ?
                    obj.oldValue === "1" ?
                        "Ativo" : "Inativo" :
                    obj.variable.includes("cnaeTypeId") ?
                        cnaeOptions.find(cnae => cnae.cnaeTypeId === parseInt(obj.oldValue)).cnaeTypeName :
                        obj.oldValue ?? '--'
        return '--';
    }

    const handleSolicitationNewValue = (obj) => {

        if (obj.newValue !== null)
            return obj.newValue?.length > 0 && obj.variable.includes('Validity') && !isNaN(Date.parse(obj.newValue)) ?
                dayjs(obj.newValue).format('DD/MM/YYYY') : obj.variable.includes("statusANTT") || obj.variable.includes("statusSocialContractId") ?
                    obj.newValue === "1" ?
                        "Ativo" : "Inativo" : obj.variable.includes("cnaeTypeId") ?
                        cnaeOptions.find(cnae => cnae.cnaeTypeId === parseInt(obj.newValue))?.cnaeTypeName : obj.newValue ?? '--'

        return '--';
    }

    useEffect(() => {
        handleOptionsCNAE()
    }, [])

    return (
        <Layout showHeader>
            <div ref={scrollRef}>
                <GridContainerSection>
                    <CarrierDetaileHeaderSection container spacing={2} >
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <ArrowLeft
                                        size={25}
                                        color={SECONDARY_COLOR}
                                        onClick={() => navigate(-1)}
                                        className="mr-3 hover-icon"
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography onClick={() => navigate(-1)} className="hover-icon">
                                        Voltar à lista de transportadoras
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <CarrierDetaileHeaderSection item>
                            <Stack direction={"row"} spacing={2}>
                                <CarrierDetailEditButton onClick={onClickHomologationRequirements}><Typography>Requisitos de Homologação</Typography></CarrierDetailEditButton>
                                {(canEdit && !carrierView) && (
                                    <CarrierDetailEditButton
                                        onClick={() => handleOpenTab(0)}
                                    >
                                        <Edit size={20} color={SECONDARY_COLOR} />
                                        <Typography>EDITAR</Typography>
                                    </CarrierDetailEditButton>
                                )}
                            </Stack>
                        </CarrierDetaileHeaderSection>
                    </CarrierDetaileHeaderSection>
                    {loading || !carrier ? (
                        <Loading loading />
                    ) : (
                        <CarrierDetailBox >
                            <CarrierDetaileHeaderSection container>
                                <TabContext value={tabSelected ? tabSelected : "1"} >
                                    <TabList onChange={handleTabChange}>
                                        <Tab
                                            label={<TabLable variant="h6">Dados da transportadora</TabLable>}
                                            value="1"
                                            sx={css`text-transform: initial;`}
                                        ></Tab>
                                        {!carrierView && <Tab
                                            label={<TabLable variant="h6">Histórico de edições</TabLable>}
                                            value="2"
                                            sx={css`text-transform: initial;`}
                                        ></Tab>}
                                        <Tab
                                            label={<TabLable variant="h6">Solicitações</TabLable>}
                                            value="3"
                                            sx={css`text-transform: initial;`}
                                        ></Tab>
                                        <Tab
                                            label={<TabLable variant="h6">Documentos</TabLable>}
                                            value="4"
                                            sx={css`text-transform: initial;`}
                                        ></Tab>
                                    </TabList>
                                    {!carrierView &&
                                        <Grid item sx={css`margin-left: auto; text-align: right; margin-right: 6px;`}>
                                            <Typography variant="body2" sx={css`color: ${DARK_GRAY};margin-left: 3px;`}>
                                                {`Desde: ${getDate(
                                                    carrier.carrierStatusHistoryLog?.historyLogCreatedAt ??
                                                    carrier.historyLog?.historyLogCreatedAt
                                                )}`}
                                            </Typography>
                                            {!isActive && (
                                                <Typography variant="body2" sx={css`color: ${DARK_GRAY};margin-left: 3px;`}>
                                                    {`Motivo: ${carrier.historyLog?.historyLogComment ||
                                                        "Motivo não informado"
                                                        }`}
                                                </Typography>
                                            )}
                                        </Grid>
                                    }
                                    {!carrierView &&
                                        <Grid item>
                                            <Typography variant="body2" sx={css`color: ${GRAY}; margin-left: 3px;`}>
                                                Situação
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="right"
                                                sx={
                                                    isActive
                                                        ? css`color: ${GRAY}; font-weight: bold;`
                                                        : css`color: ${RED}; font-weight: bold;`
                                                }
                                            >
                                                {carrier.carrierStatus.carrierStatusName}
                                            </Typography>
                                        </Grid>
                                    }
                                    {canEdit &&
                                        <Grid item>
                                            <CarrierDetailSwitch
                                                checked={isActive}
                                                onClick={handleSwitchClick}
                                            />
                                        </Grid>
                                    }
                                    {(carrierView || (configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.homologation || configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.development)) && <Grid item><CarrierDetailEditButton onClick={() => navigate(routes.requestCarrierEdit, { state: { carrierId: carrier.carrierId } })}>Solicitar Edição</CarrierDetailEditButton></Grid>}
                                    <Grid container sx={css`border-top: solid;border-width: 0.7px;border-color: ${LIGHT_GRAY};`}>
                                        <TabPanel value="1" sx={css`width: inherit;padding: 24px 0px 24px 0px;`}>
                                            <CarrierData pendingSolicitations={pendingSolicitations} carrierView={carrierView} contactData={contactUsers} data={carrier} handleOpenTab={handleOpenTab} editPermition={canEdit} />
                                        </TabPanel>
                                        <TabPanel value="2" sx={css`width: inherit;padding: 24px 0px 24px 0px;`}>
                                            {carrier?.historyLogs?.length === 0 ? (
                                                <Grid sx={css`display: flex; flex-direction: column; align-items: center; justify-content: center; height: calc(100vh - 250px);`}>
                                                    <Typography><AlertTriangle size={15} color={YELLOW} /> Ainda não foram realizadas edições nesta transportadora!</Typography>
                                                    <CarrierDetailEditButton
                                                        onClick={() => handleOpenTab(0)}
                                                    >
                                                        <Edit size={15} color={SECONDARY_COLOR} />
                                                        <Typography>EDITAR TRANSPORTADORA</Typography>
                                                    </CarrierDetailEditButton>
                                                </Grid>
                                            ) : (
                                                <>
                                                    {carrier?.historyLogs?.map((log, index) => (
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={"panel-content-" + index}
                                                            >
                                                                <TabLable sx={css`overflow: hidden; text-overflow: ellipsis; white-space: normal;`}>
                                                                    <b>{getDate(log.historyLogCreatedAt)} às {getHour(log.historyLogCreatedAt)}</b> - Editado por: {log.historyLogCreatedBy?.userName}
                                                                </TabLable>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <TableRow id="details" sx={css`display: inline-table; width: 100%;`} >
                                                                    <TableCell>
                                                                        <TableContainer>
                                                                            <Table>
                                                                                <TableHead sx={css`background-color: white;`}>
                                                                                    <TableRow >
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};`} width={"370px"}>Dados Atualizados</TableCell>
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};`}>Dados Antigos</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody sx={css`background-color: white;`}>
                                                                                    {log.actions?.map((action, index2) => (
                                                                                        <TableRow >
                                                                                            <TableCell sx={css`border-right: 1px solid rgb(224, 224, 224);padding: 7px;`}>
                                                                                                <Table>
                                                                                                    <TableBody >
                                                                                                        <Table>
                                                                                                            <TableRow >
                                                                                                                <CarrierDetailUpdateTitleData >{action.historyLogActionGroup}</CarrierDetailUpdateTitleData>
                                                                                                                <CarrierDetailUpdateTitleData width={"250px"}>{action.historyLogActionVariable}</CarrierDetailUpdateTitleData>
                                                                                                            </TableRow>
                                                                                                            <TableRow>
                                                                                                                <CarrierDetailUpdateTitleData ></CarrierDetailUpdateTitleData>
                                                                                                                <CarrierDetailUpdateData width={"250px"}>{action.historyLogActionNewValue.replace(" 00:00:00", "")}</CarrierDetailUpdateData>
                                                                                                            </TableRow>
                                                                                                        </Table>
                                                                                                    </TableBody>
                                                                                                </Table>
                                                                                            </TableCell >
                                                                                            <TableCell >
                                                                                                <Table>
                                                                                                    <TableBody>
                                                                                                        <TableRow>
                                                                                                        </TableRow>
                                                                                                        <TableRow sx={css`color: ${GRAY};font-weight: bold;`}>
                                                                                                            {action.historyLogActionOldValue.includes("1/1/0001 12:00:00 AM") ?  "--": action.historyLogActionOldValue.replace(" 00:00:00", "")}
                                                                                                        </TableRow>
                                                                                                    </TableBody>
                                                                                                </Table>
                                                                                            </TableCell >
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        /*
                                                        <Grid sx={
                                                            openDetails[index]
                                                                ? gridDetailActive
                                                                : gridDetail}>
                                                            <TableRow sx={css`display: inline-table; width: 100%;`} onClick={() => handleChevronClick(index)}>
                                                                <CarreirDetailTableCell align="left">
                                                                    <Typography sx={css`overflow: hidden; text-overflow: ellipsis; white-space: nowrap;`}>
                                                                        <b>{getDate(log.historyLogCreatedAt)} às {getHour(log.historyLogCreatedAt)}</b> - Editado por: {log.historyLogCreatedBy?.userName}
                                                                    </Typography>
                                                                </CarreirDetailTableCell>
                                                                <TableCell sx={css`& .MuiTableCell-root {padding: 0px;}`}>
                                                                    {!openDetails[index] ? (
                                                                        <ChevronDown
                                                                            size={20}
                                                                            onClick={() => handleChevronClick(index)}
                                                                        ></ChevronDown>
                                                                    ) : (
                                                                        <ChevronUp
                                                                            size={20}
                                                                            onClick={() => handleChevronClick(index)}
                                                                        ></ChevronUp>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            {openDetails[index] && (
                                                                
                                                            )}
                                                        </Grid>*/
                                                    ))}
                                                </>
                                            )}
                                        </TabPanel>
                                        <TabPanel value="3" sx={css`width: inherit;padding: 24px 0px 24px 0px;`}>
                                            {solicitationList.map(el => {
                                                return <Accordion sx={css`margin-bottom: 10px;`}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={"panel-content-s" + el.id}
                                                    >

                                                        <TabLable sx={css`overflow: hidden; text-overflow: ellipsis; white-space: normal;color:${PRIMARY_COLOR};display:inline;`}>
                                                            <b>ID {el.id < 10 ?
                                                                '000' + el.id : el.id < 100 ?
                                                                    '00' + el.id : el.id < 1000 ?
                                                                        '0' + el.id : el.id} · {getDate(el.dataInc)} às {getHour(el.dataInc)}</b> - Solicitado por: {el.userName}
                                                        </TabLable>
                                                        <Typography sx={css`display: inline;position: absolute;right: 50px;font-family: YaraMax;
                                                            font-size: 14px;
                                                            font-weight: bold;
                                                            line-height: 17px;
                                                            letter-spacing: 0.15000000596046448px;
                                                            text-align: left;
                                                            color: ${el.solicitationStatus?.solicitationStatusId === 1 ? '#2777B8' : '#507A07'};`
                                                        }>
                                                            <Grid container justifyContent={"center"} spacing={0.5}>
                                                                <Grid item marginTop={0.2}>
                                                                    {el.solicitationStatus?.solicitationStatusId === 1 ? <Flag size={14} style={css`margin-top:10px;`} /> : <CheckSquare size={14} />}
                                                                </Grid>
                                                                <Grid item>
                                                                    {el.solicitationStatus?.solicitationStatusName}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography variant="subtitle2" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Solicitações de edição</Typography>
                                                        <List>
                                                            {el.itens.map(obj => {
                                                                if (['REGULATORY', 'REPRESENTATIVE', 'COMERCIAL'].includes(obj.variable) && (obj.contacts.filter(con => con.action.includes('add')).length > 0 || obj.contacts.filter(con => con.action.includes('update')).length > 0)) {
                                                                    return (<div>
                                                                        <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contato {LABLE_REQUEST[obj.variable]} -</Typography>
                                                                        <List>{obj.contacts.map((elContact) => {
                                                                            if (elContact.action.includes('add') || elContact.action.includes('update')) {
                                                                                return <ListItem>
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableHead sx={css`background-color: white;`}>
                                                                                                <TableRow >
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"15%"}>Nome</TableCell>
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"15%"}>Email</TableCell>
                                                                                                    {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}>Telefone</TableCell>}
                                                                                                    {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}>CPF</TableCell>}
                                                                                                    {obj.variable.includes("COMERCIAL") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}></TableCell>}

                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody sx={css`background-color: white;`}>
                                                                                                <TableRow >
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.name}</TableCell>
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.email}</TableCell>
                                                                                                    {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.phone ? elContact.phone : '--'}</TableCell>}
                                                                                                    {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.document ? elContact.document : '--'}</TableCell>}
                                                                                                    {obj.variable.includes("COMERCIAL") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;`}></TableCell>}
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;`}>{obj.solicitationItemStatus?.solicitationItemStatusId === 1 ? obj.solicitationItemStatus?.solicitationItemStatusName : <Typography sx={css`color:${RED};`}>{obj.solicitationItemStatus?.solicitationItemStatusName}</Typography>}</TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </TableHead>
                                                                                    </Table>
                                                                                </ListItem>
                                                                            }
                                                                        })}</List>
                                                                    </div>)
                                                                } else if (!['REGULATORY', 'REPRESENTATIVE', 'COMERCIAL'].includes(obj.variable))
                                                                    return <ListItem>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableHead sx={css`background-color: white;`}>
                                                                                    <TableRow >
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[obj.variable]}</TableCell>
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody sx={css`background-color: white;`}>
                                                                                    <TableRow >
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{handleSolicitationCell(obj)}</TableCell>
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{handleSolicitationNewValue(obj)}</TableCell>
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{obj.extension ? LABLE_REQUEST[obj?.variable]?.replace(' ', '') + obj?.extension : ''}</TableCell>
                                                                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{obj.solicitationItemStatus?.solicitationItemStatusId === 1 ? obj.solicitationItemStatus?.solicitationItemStatusName : <Typography sx={css`color:${RED};`}>{obj.solicitationItemStatus?.solicitationItemStatusName}</Typography>}</TableCell>
                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </TableHead>
                                                                        </Table>
                                                                    </ListItem>
                                                            })}
                                                        </List>
                                                        <br />
                                                        {el.itens.map(obj => {
                                                            if (['REGULATORY', 'REPRESENTATIVE', 'COMERCIAL'].includes(obj.variable) && obj.contacts.filter(con => con.action.includes('remove')).length > 0) {

                                                                return (<div>
                                                                    <Typography variant="subtitle2" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Solicitações de exclusão</Typography>
                                                                    <List>
                                                                        <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contato {LABLE_REQUEST[obj.variable]} -</Typography>
                                                                        <List>{obj.contacts.map((elContact) => {
                                                                            if (elContact.action.includes('remove')) {
                                                                                return <ListItem>
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableHead sx={css`background-color: white;`}>
                                                                                                <TableRow >
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Nome</TableCell>
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Email</TableCell>
                                                                                                    {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"370px"}>Telefone</TableCell>}
                                                                                                    {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"370px"}>CPF</TableCell>}

                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody sx={css`background-color: white;`}>
                                                                                                <TableRow >
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.name}</TableCell>
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.email}</TableCell>
                                                                                                    {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.phone ? elContact.phone : '--'}</TableCell>}
                                                                                                    {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.document ? elContact.document : '--'}</TableCell>}
                                                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>{obj.solicitationItemStatus?.solicitationItemStatusId === 1 ? obj.solicitationItemStatus?.solicitationItemStatusName : <Typography sx={css`color:${RED};`}>{obj.solicitationItemStatus?.solicitationItemStatusName}</Typography>}</TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </TableHead>
                                                                                    </Table>
                                                                                </ListItem>
                                                                            }
                                                                        })}</List>

                                                                    </List>
                                                                </div>)
                                                            }
                                                        })
                                                        }
                                                        {el.observation && <div>
                                                            <Typography variant="subtitle2" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Observação</Typography>
                                                            <TextArea rows={4} value={el.observation} disabled />
                                                        </div>
                                                        }

                                                        <br />
                                                        <br />
                                                        {(el.solicitationStatus?.solicitationStatusId === 1) && <Button onClick={() => handleCancelSolicitation(el.id)} startIcon={<XCircle size={20} />} variant="outlined" color="error" sx={css`position: absolute; right: 30px;bottom:30px;font-weight: 600;font-size: 14px;`}>
                                                            Cancelar Solicitação
                                                        </Button>}

                                                    </AccordionDetails>
                                                </Accordion>
                                            })}
                                        </TabPanel>
                                        <TabPanel value="4" sx={css`width: inherit;padding: 24px 0px 24px 0px;`}>
                                            <CarrierDocument carrierView={carrierView} solicitationDocsList={solicitationDocsList} cnaeOptions={cnaeOptions} apiNotification={apiNotification} getCarrierDetails={getCarrierDetails}/>
                                        </TabPanel>
                                    </Grid>
                                </TabContext>
                            </CarrierDetaileHeaderSection>
                            <Typography
                                variant="body2"
                                align="right"
                                sx={css`color: ${GRAY};`}
                            >{`Incluso: ${getDate(carrier.historyLogFirst?.historyLogCreatedAt)} / por ${carrier.historyLogFirst?.historyLogCreatedBy?.userName || "--"
                                }`}</Typography>
                        </CarrierDetailBox>
                    )}
                </GridContainerSection>
                <CarrierModalSetStatus
                    open={isDialogStatusOpen}
                    onClose={handleDialogStatusCancel}
                    isActive={isActive}
                    carrier={carrier}
                    onConfirm={handleDialogStatusConfirm}
                />
                <CarrierModalEdit
                    open={isDialogOpen}
                    tab={tabActive}
                    onClose={handleDialogEditCancel}
                    carrier={carrier}
                    onConfirm={handleDialogEditConfirm}
                    contacts={contactUsers}
                />

                <HomologationModal
                    open={modalHomologation}
                    onClose={onClosModalHomologation}
                />
            </div>
            {contextHolderModal}
            {contextHolder}
        </Layout>
    );
}

const GridContainerSection = styled(Grid)`
    padding: 2rem 2rem 2rem ${FIXED_DRAWER_WIDTH};
    min-height: calc(100vh - ${FIXED_HEADER_HEIGHT});
    background-color: ${LIGHT_GRAY};

    @media only screen and (max-width: 768px){
        padding: 2rem 2px;
    }
`
const CarrierDetaileHeaderSection = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const CarrierDetailBox = styled(Box)`
    background-color: WHITE;
    border-radius: 10px;
    margin-left: 6px;
    margin-top: 6px;
    padding: 6px;
`
const CarrierDetailSwitch = styled(Switch)`
    & .MuiSwitch-input {
        width: auto;
        left: auto;
    }
`
const CarrierDetailEditButton = styled.button`
    font-size: 11px;
    border: 1.5px solid ${SECONDARY_COLOR};
    background-color: transparent;
    color: ${SECONDARY_COLOR};
    border-radius: 4px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiSvgIcon-root {
        padding-right: 10px;
    }
    &:hover{
        background-color: ${PRIMARY_COLOR};
        color: white;
        cursor: pointer;
        border: none;
    }
    &:hover .MuiTypography-root {
        color: white
    }
    & .MuiTypography-root {
        font-size: 13px;
        color: ${SECONDARY_COLOR}
    }
    & svg {
        margin-right: 10px;
        color: ${SECONDARY_COLOR}
    }
`

const CarreirDetailTableCell = styled(TableCell)`
    color: ${PRIMARY_COLOR};
    & .MuiTableCell-root {
        padding: 16px 8px 16px 8px/
    },
    & .MuiIconButton-root:hover: {
        background-color: inherit;
    }
`
const CarrierDetailUpdateTitleData = styled(TableCell)`
    height: 25px;
    font-size: 12px;
    border-bottom: none;
    padding-top: 1px;
    padding-bottom: 1px;
`
const CarrierDetailUpdateData = styled(TableCell)`
    color: ${SECONDARY_COLOR};
    font-weight: bold;
    border-bottom: none;
    padding-top: 1px;
    padding-bottom: 1px;
`

const TabLable = styled(Typography)`
    @media only screen and (max-width: 768px){
        font-size: 14px;
    }
`

const gridDetail = css`
    border-radius: 10px;
    border-top-color: ${LIGHT_GRAY};
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-color: ${LIGHT_GRAY};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: ${LIGHT_GRAY};
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: ${LIGHT_GRAY};
    border-right-style: solid;
    border-right-width: 1px;
    margin-bottom: 10px;
`

const gridDetailActive = css`
    border-radius: 10px;
    border-top-color: ${LIGHT_GRAY};
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-color: ${LIGHT_GRAY};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-color: ${LIGHT_GRAY};
    border-right-style: solid;
    border-right-width: 1px;
    margin-bottom: 10px;
    border-left-color: ${SECONDARY_COLOR};
    border-left-style: solid;
    border-left-width: 3px,;
`

export default CarrierDetails;

/**
 * <List>
                                                <ListItem>
                                                    <Table>
                                                        <TableHead>
                                                            <TableHead sx={css`background-color: white;`}>
                                                                <TableRow >
                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Valor atual {LABLE_REQUEST[attr]}</TableCell>
                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Alterar para</TableCell>
                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"370px"}>Anexo</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody sx={css`background-color: white;`}>
                                                                <TableRow >
                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{carr[attr] ? attr.includes('Validity') ? dayjs(carr[attr]).format('DD/MM/YYYY') : carr[attr] : '--'}</TableCell>
                                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr] ? attr.includes('Validity') ? dayjs(re[attr]).format('DD/MM/YYYY') : re[attr] : '--'}</TableCell>
                                                                    {re[attr + 'File'] && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{re[attr + 'File'].name}</TableCell>}
                                                                </TableRow>
                                                            </TableBody>
                                                        </TableHead>
                                                    </Table>
                                                </ListItem>
                                            </List>
 */