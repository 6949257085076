import axios from "../axiosInstance";

class DocumnetService{
    async getDocs(id){
        return await axios.get(`SolicitationDocument?carrierid=${id}`);
    }

    async sendDocsYara(carrierId, variable, file, valueVariable){
        return await axios.post(`SolicitationDocument?carrierid=${carrierId}&variable=${variable}&newvalue=${valueVariable}`,file,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
    }
}

export default new DocumnetService;