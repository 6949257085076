import React, { useContext, useEffect, useState } from "react"
import { Layout } from "../../layout";
import { FIXED_DRAWER_WIDTH, FIXED_HEADER_HEIGHT } from "../../../shared/utils/Constants";
import styled from "@emotion/styled"
import { LIGHT_GRAY, PRIMARY_COLOR, WHITE } from "../../../theme";
import { AppBar, Button, Divider, Grid, List, Toolbar, Typography } from "@mui/material";
import { css } from "@emotion/react"
import LetterListItem from "./components/LetterListItem";
import { Box, Stack } from "@mui/system";
import { Plus } from "react-feather";
import useLetterList from "../hooks/useLetterList";
import AddLetterModal from "./components/AddLetterModal";
import useAddNewLetter from "../hooks/useAddNewLetter";
import LetterContent from "./components/LetterContent";
import { ReactReduxContext } from "react-redux";
import { accessType } from "../../../shared/utils/enum";
import { Spin } from "antd";
import './style.css'

const NewsLetterPage = () => {
    const { store } = useContext(ReactReduxContext)
    const [carrierView, setCarrierView] = useState(false);

    useEffect(() => {
        const { user: {
            profile
        } } = store.getState();

        if (profile === accessType.carrier || profile === accessType.hiring || profile === accessType.fullView) {
            setCarrierView(true);
        }
    }, [store])

    const {
        selectedLetter,
        letterList,
        onDeleteLetter,
        loadList,
        onClickLetter,
    } = useLetterList();

    const {
        openModal,
        titleValue,
        contentValue,
        contextHolder,
        doc,
        load,
        emailCheck,
        handleTestEmail,
        handleCheckboxOnChange,
        onCancel,
        handleFile,
        sendLetter,
        onChangeTitle,
        onChangeContent,
        onChangeModalState
    } = useAddNewLetter(loadList);

    const renderListLetter = () => {
        const listRender = [];

        letterList.forEach((el) => {
            listRender.push(<LetterListItem carrierView={carrierView} letter={el} selected={selectedLetter} onSelectLetter={onClickLetter} onDeleteLetter={onDeleteLetter} />)
            listRender.push(<Divider component="li" />)
        })
        return listRender;
    }

    return (
        <Spin spinning={load} fullscreen>
            <Layout showHeader>
                <GridContainerSection>
                    {contextHolder}
                    <AppBar component="nav" position="sticky" sx={css`background-color: #FFF; padding: 15px; box-shadow: none;`}>
                        <Toolbar>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: PRIMARY_COLOR, fontWeight: "bold" }}
                            >
                                Comunicados
                            </Typography>
                            {!carrierView && <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<Plus />}
                                    sx={css`max-width: 140;
                                    height: 45px;
                                    border-radius: 10px;
                                    margin-left: auto;
                                    background-color: WHITE;`
                                    }
                                    onClick={onChangeModalState}
                                >
                                    Adicionar comunicado
                                </Button>
                            </Box>}

                        </Toolbar>
                    </AppBar>
                    <Stack direction={'row'}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: LIGHT_GRAY, marginTop: "-7px;", overflow: "auto", maxHeight: '48rem' }}>
                            <Divider component="li" />
                            {
                                renderListLetter()
                            }
                        </List>
                        <Box sx={{ bgcolor: WHITE, width: '100%', height: '48rem', marginTop: '2px', padding: '10px', paddingLeft: '25px;' }}>
                            <LetterContent letter={letterList.find(el => selectedLetter === el.id)} />
                        </Box>
                    </Stack>
                </GridContainerSection>
                <AddLetterModal
                    open={openModal}
                    onClose={onChangeModalState}
                    titleValue={titleValue}
                    onChangeTitle={onChangeTitle}
                    contentValue={contentValue}
                    onChangeContent={onChangeContent}
                    sendLetter={sendLetter}
                    doc={doc}
                    handleFile={handleFile}
                    onCancel={onCancel}
                    load={load}
                    emailCheck={emailCheck}
                    handleCheckboxOnChange={handleCheckboxOnChange}
                    handleTestEmail={handleTestEmail}
                />
            </Layout >
        </Spin>)
}

const GridContainerSection = styled(Grid)`
    padding: 1rem 0rem 0rem ${FIXED_DRAWER_WIDTH};
    min-height: calc(100vh - ${FIXED_HEADER_HEIGHT});
    background-color: ${LIGHT_GRAY};

    @media only screen and (max-width: 768px){
        padding: 2rem 2px;
    }
`


export default NewsLetterPage;