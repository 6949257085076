import { useCallback, useEffect, useState } from "react";
import NewsLetterService from "../../../services/NewsLetterService";

const useLetterList = () => {

    const [selectedLetter, setSelectedLetter] = useState();

    const [letterList, setLetterList] = useState([]);

    async function loadList() {
        const listl = await NewsLetterService.getNewsletterList();
        setLetterList(listl.data.reverse().map(el => {
            return {
                "id": el.newsletterId,
                "title": el.newsletterTitle,
                "content": el.newsletterContent,
                "createdAt": el.newsletterCreatedAt,
                "author": el.newsletterAuthor,
                "file": el.newsletterFile
            }
        }))
    }

    useEffect(() => {
        
        loadList();
    }, [])

    useEffect(()=>{
        setSelectedLetter(letterList[0]?.id);
    },[letterList])

    const onClickLetter = (letterId) => {
        setSelectedLetter(letterId);
    }

    const onDeleteLetter = (id) =>{
        NewsLetterService.deleteLetter(id);
        loadList();
    }

    return {
        selectedLetter,
        letterList,
        onDeleteLetter,
        loadList,
        onClickLetter,
    }
}

export default useLetterList;