import React, { useEffect, useState } from "react";
import { SwipeableDrawer, Drawer, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE, RED } from "../../../theme";
import SidebarListItems from "./SidebarListItems";
import {
  BP_TITLE,
  FIXED_DRAWER_WIDTH,
  MOBILE_WIDTH,
  SWIPEABLE_DRAWER_WIDTH,
  routes
} from "../../../shared/utils/Constants";
import Logo from "../../../shared/img/Logo.svg";
import { ChevronLeft, ChevronRight, HelpCircle, Mail } from "react-feather";
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { deliveryVersion } from "../../../shared/utils/enum";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_NEWSLETTERLIST_SELECTED, SET_NORMALCARRIER_SELECTED, SET_SOLICITATIONLIST_SELECTED, SET_USERCONTROLERLIST_SELECTED, updateBarSelectedStore } from "../../../store/SideBarSelectedStore";

const Sidebar = ({ onMobile, openDrawer, setOpenDrawer }) => {
  const [redirect, setRedirect] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();

  const classes = useStyles({
    isMobile: window.innerWidth <= MOBILE_WIDTH,
    openDrawer: openDrawer,
    chevronSize: 25
  });

  const toggleDrawer = open => event => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenDrawer(open);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    });
  };


  const version = (
    <Typography variant="caption" className={classes.version}>
      {`v${deliveryVersion[deliveryVersion.length - 1].version}`}
    </Typography>
  );
  
  useEffect(()=>{
    console.log(window.location.pathname)
    switch(window.location.pathname){
      case routes.listCarriers:
        dispatch(updateBarSelectedStore(SET_NORMALCARRIER_SELECTED));
        break;
      case routes.solicitationsList:
        dispatch(updateBarSelectedStore(SET_SOLICITATIONLIST_SELECTED));
        break;
      case routes.usersList:
        dispatch(updateBarSelectedStore(SET_USERCONTROLERLIST_SELECTED));
        break;
      case routes.newsLetter:
        dispatch(updateBarSelectedStore(SET_NEWSLETTERLIST_SELECTED));
        break;
    }
  }, [])

  const onClickItem = route => {

    switch (route) {
      case routes.listCarriers:
        dispatch(updateBarSelectedStore(SET_NORMALCARRIER_SELECTED));
        break;
      case routes.solicitationsList:
        dispatch(updateBarSelectedStore(SET_SOLICITATIONLIST_SELECTED));
        break;
      case routes.usersList:
        dispatch(updateBarSelectedStore(SET_USERCONTROLERLIST_SELECTED));
        break;
      case routes.newsLetter:
        dispatch(updateBarSelectedStore(SET_NEWSLETTERLIST_SELECTED));
        break;

    }

    setRedirect(<Navigate to={route} />);
  };



  return (
    <Grid item xs={12}>
      {!onMobile && <Drawer variant="permanent" open className={classes.permanentMenu}>
        <Grid item className="mt-6 mb-2">
          <img className={classes.img} src={Logo} alt="Logo Yara" />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <SidebarListItems open={openDrawer} onClickItem={onClickItem} icons />
          </Grid>
        </Grid>
        {!openDrawer && (
          <ChevronRight
            size={25}
            onClick={() => setOpenDrawer(true)}
            className={`${classes.sidebarController} hover-icon`}
          ></ChevronRight>
        )}
        {version}
      </Drawer>}

      <SwipeableDrawer
        anchor={onMobile ? "top" : "left"}
        open={openDrawer}
        onOpen={toggleDrawer(!openDrawer)}
        onClose={toggleDrawer(!openDrawer)}
        className={classes.drawer}
        disableDiscovery
      >
        <Grid item container className="mt-6 mb-2">
          <Grid item>
            <img className={classes.img} src={Logo} alt="Logo Yara" />
          </Grid>
          {onMobile && (
            <Grid item>
              <Typography align="center" variant="h6" component="h1" className={classes.headerTitle}>
                {BP_TITLE.pt}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <SidebarListItems open={openDrawer} onClickItem={onClickItem} icons names />
          </Grid>
          {onMobile && (
            <Grid item container xs={12} style={{ margin: 20 }}>
              <Grid item>
                <HelpCircle
                  color={RED}
                  size={25}
                  className={`${classes.help} hover-icon`} />
              </Grid>
              <Grid item>
                <Typography>Caso esteja enfrentando problemas por aqui, entre em contato através dos canais abaixo:</Typography>
              </Grid>
              <Grid item>
                <Mail size={18} color={SECONDARY_COLOR} style={{ marginRight: '8px' }} />
              </Grid>
              <Grid item>
                <Typography color={SECONDARY_COLOR}><a href="mailto:logistica_yara@yara.com">logistica_yara@yara.com</a></Typography>
              </Grid>
              <Grid item>
                <IconButton
                  color={SECONDARY_COLOR}
                  onClick={() => copyToClipboard('logistica_yara@yara.com')}

                >
                  <FileCopyIcon color={SECONDARY_COLOR} style={{ fontSize: 15, color: SECONDARY_COLOR }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!onMobile && <ChevronLeft
          size={25}
          onClick={() => setOpenDrawer(false)}
          className={`${classes.sidebarController} hover-icon`}
        ></ChevronLeft>}
        {version}
      </SwipeableDrawer>
      {redirect}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  permanentMenu: {
    "& .MuiDrawer-paperAnchorLeft": {
      width: FIXED_DRAWER_WIDTH
    }
  },
  drawer: {
    "& .MuiDrawer-paperAnchorLeft": {
      backgroundColor: WHITE,
      width: SWIPEABLE_DRAWER_WIDTH,
      overflowX: "hidden"
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(255, 255, 255, 0)"
    }
  },
  help: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  img: {
    width: 50,
    height: 50,
    display: "flex",
    marginLeft: "20px",
    marginRight: "20px"
  },
  sidebarController: props => ({
    borderRadius: "15px",
    border: "solid 2px",
    color: SECONDARY_COLOR,
    backgroundColor: WHITE,
    position: "fixed",
    top: "50%",
    left: props.openDrawer
      ? `calc(${SWIPEABLE_DRAWER_WIDTH} - ${props.chevronSize * 0.5}px)`
      : `calc(${FIXED_DRAWER_WIDTH} - ${props.chevronSize * 0.5}px)`
  }),
  version: {
    position: "fixed",
    top: "97%",
    marginLeft: "20px",
    color: PRIMARY_COLOR
  }
}));

export default Sidebar;
