import React, { useContext, useEffect, useState } from "react";
import { List } from "@mui/material";
import SidebarItem from "./SidebarItem";
import { accessType, sidebarItems, sidebarItemsNames } from "../../../shared/utils/enum";
import { ReactReduxContext, useSelector } from "react-redux";
import { routes } from "../../../shared/utils/Constants";

const SidebarListItems = props => {
  const { open, onClickItem, icons, names } = props;
  const { store } = useContext(ReactReduxContext);
  const [canControlAccess, setCanControlAccess] = useState(false);
  const selectedControl = useSelector(state => state.sideBarSelectedStore)

  useEffect(() => {
      const { user: {
          profile
      } } = store.getState();

      if (profile === accessType.logisticsAnalyst) {
        setCanControlAccess(true);
      }
  }, [store])

  const handleIcon = (item)=>{
    switch (item.route) {
      case routes.listCarriers:
        return selectedControl.normalCarrierList ? item.selectedIcon : item.icon;
      case routes.solicitationsList:
        return selectedControl.solicitationsList ? item.selectedIcon : item.icon;
      case routes.usersList:
        return selectedControl.userControlerList ? item.selectedIcon : item.icon;
      case routes.newsLetter:
        return selectedControl.newsLetterList ? item.selectedIcon : item.icon;
    }
  }

  return (
    <List>
      {sidebarItems.map((item, index) => {
        if(item.name === sidebarItemsNames.accessManagement && !canControlAccess) return;
        if(item.name === sidebarItemsNames.solicitations && !canControlAccess) return;
        return (
        <SidebarItem
          key={item.name + index}
          icon={handleIcon(item)}
          name={item.name}
          open={open}
          icons={icons}
          names={names}
          handleClick={() => onClickItem(item.route)}
        />
      )})}
    </List>
  );
};

export default SidebarListItems;
