import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { Login } from "./login";
import DeniedAccess from "./shared/components/DeniedAccess";
import PageNotFound from "./shared/components/PageNotFound";
import UserNotFound from "./shared/components/UserNotFound";
import { AuthenticationState, AzureAD } from "react-aad-msal";
import { authProvider } from "./authProvider";
import { routes, storageKeyBffToken, storageKeyExpiresOn, storageKeyToken } from "./shared/utils/Constants";
import * as actions from "./store/UserStore";
import store from "./store";
import LoginManager from "./login/LoginManager";
import LogoutProvider from "./contexts/LogoutContext";
import ExpiredSection from "./features/expiredSectionModal/views/ExpiredSection";
import ListCarriers from "./features/listCarriers/views/ListCarriers";
import CarrierDetails from "./features/carrierDetails/views/CarrierDetails";
import { accessType } from "./shared/utils/enum";
import UsersList from "./features/usersList/view/UsersList";
import SolicitationsList from "./features/solicitationsList/view/SolicitationsList";
import RequestEditCarrier from "./features/carrierDetails/views/RequestEditCarrier";
import ExternalLogin from "./login/views/ExternalLogin";
import RegisterPassword from "./login/views/RegisterPassword";
import SolicitationReview from "./features/solicitationReview/views/SolicitationReview";
import { jwtDecode } from "jwt-decode";
import ForgetPassWord from "./login/views/ForgetPassword";
import ResetPassword from "./login/views/ResetPassword";
import NewsLetterPage from "./features/newsLetter/views/NewsLetter";

const URLPaths = props => {
    const { authenticateUser, isAuthenticated, accessToken, userProfile, updateUser, externalUser, updateExternal } = props;

    const [loginError, setLoginError] = useState(false);

    useEffect(() => {
        const getUserData = async () => {
            const user = await LoginManager.authenticate();

            if (user?.requestError) setLoginError(true);
            else {
                updateUser({
                    ...user,
                    profile: user?.profile?.profileName
                });
            }
        };

        if (isAuthenticated && accessToken) getUserData();
        if (isAuthenticated && externalUser) {
            const { exp, id, ad, name, email, idProfile, profile } = jwtDecode(sessionStorage.getItem(storageKeyBffToken))

            const user = {
                id: parseInt(id),
                adUser: ad,
                name: name,
                email: email,
                profile: {
                    id: parseInt(idProfile),
                    profileName: profile,
                },
                requestError: false,
            };

            updateUser({
                ...user,
                profile: user?.profile?.profileName
            });
        }
    }, [isAuthenticated, updateUser, accessToken, updateExternal]);

    const AuthRequireRoute = ({ children, access }) => {
        const hasAccess = Array.isArray(access) ? access.includes(userProfile) : userProfile === access;

        return (
            hasAccess ? (
                children
            ) : (
                <Navigate to={routes.deniedAccess} />
            )
        );
    };

    const DefaultRouteAth = ({ children, auth, accessToken, userProfile }) => {
        let previousPath = sessionStorage.getItem("redirect");
        let defaultRoute;
        if (previousPath) defaultRoute = previousPath;
        else defaultRoute = routes.listCarriers;

        return (auth === AuthenticationState.Authenticated && accessToken && userProfile ? <Navigate to={defaultRoute} /> : children)
    }

    document.title = "Digital Homologation";

    return (
        <AzureAD provider={authProvider} reduxStore={store}>
            {({ _login, logout, authenticationState, _error, _accountInfo }) => {
                if (authenticationState === AuthenticationState.Authenticated && !accessToken && !externalUser) {
                    authProvider.getAccessToken().then(token => {
                        sessionStorage.setItem(storageKeyExpiresOn, token.expiresOn);
                        sessionStorage.setItem(storageKeyToken, token.accessToken);
                        authenticateUser();
                    });
                }
                let external = sessionStorage.getItem('externalUser');
                if (!externalUser && external) {
                    authenticateUser();
                    updateExternal();
                }

                return (
                    <LogoutProvider logout={logout}>
                        {authenticationState !== AuthenticationState.Authenticated && (
                            <Routes>
                                <Route exact path={routes.logIn} element={<Login />} />
                                <Route
                                    render={r => {
                                        return (
                                            <Navigate
                                                to={{
                                                    pathname: routes.logIn,
                                                    state: { from: r.location }
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </Routes>
                        )}

                        {loginError && (
                            <Routes>
                                <Route exact path={routes.userNotFound} element={<UserNotFound />} />
                                <Route render={r => <Navigate to={routes.userNotFound} />} />
                            </Routes>
                        )}

                        {((authenticationState === AuthenticationState.Authenticated &&
                            accessToken &&
                            userProfile) || (externalUser && isAuthenticated)) && (
                                <>
                                    <ExpiredSection logout={logout} />
                                    <Routes>
                                        <Route exact path={routes.logIn} element={<Login />} />
                                        <Route
                                            exact
                                            path={routes.listCarriers}
                                            element={
                                                <AuthRequireRoute access={[accessType.logisticsAnalyst, accessType.fullView, accessType.carrier, accessType.hiring]}>
                                                    <ListCarriers userProfile={userProfile} />
                                                </AuthRequireRoute>
                                            }
                                        />
                                        <Route
                                            exact
                                            path={routes.newsLetter}
                                            element={
                                                <AuthRequireRoute access={[accessType.logisticsAnalyst, accessType.fullView, accessType.carrier, accessType.hiring]}>
                                                    <NewsLetterPage userProfile={userProfile} />
                                                </AuthRequireRoute>
                                            }
                                        />
                                        <Route
                                            exact
                                            path={routes.carrierDetails}
                                            element={
                                                <AuthRequireRoute access={[accessType.logisticsAnalyst, accessType.fullView, accessType.carrier, accessType.hiring]}>
                                                    <CarrierDetails userProfile={userProfile} />
                                                </AuthRequireRoute>
                                            }
                                        />
                                        <Route
                                            exact
                                            path={routes.requestCarrierEdit}
                                            element={<RequestEditCarrier />}
                                        />
                                        <Route
                                            exact
                                            path={routes.usersList}
                                            element={
                                                <AuthRequireRoute access={[accessType.logisticsAnalyst]}>
                                                    <UsersList userProfile={userProfile} />
                                                </AuthRequireRoute>
                                            }
                                        />
                                        <Route
                                            exact
                                            path={routes.solicitationsList}
                                            element={
                                                <AuthRequireRoute access={[accessType.logisticsAnalyst]}>
                                                    <SolicitationsList userProfile={userProfile} />
                                                </AuthRequireRoute>
                                            }
                                        />
                                        <Route
                                            exact
                                            path={routes.solicitationReview}
                                            element={
                                                <AuthRequireRoute access={[accessType.logisticsAnalyst]}>
                                                    <SolicitationReview />
                                                </AuthRequireRoute>
                                            }
                                        />
                                        <Route exact path={routes.deniedAccess} element={<DeniedAccess />} />
                                        <Route exact path={routes.notFound} element={<PageNotFound />} />
                                        <Route render={r => <Navigate to={routes.notFound} />} />
                                    </Routes>
                                </>
                            )}

                        <Routes>
                            <Route
                                exact
                                path={"/"}
                                element={
                                    <DefaultRouteAth auth={authenticationState} accessToken={accessToken} userProfile={userProfile}>
                                        <Login />
                                    </DefaultRouteAth>
                                }
                            />

                            <Route
                                exact
                                path={routes.exLogIn}
                                element={
                                    <ExternalLogin />
                                }
                            />

                            <Route
                                exact
                                path={routes.registerPassword}
                                element={
                                    <RegisterPassword />
                                }
                            />
                            <Route
                                exact
                                path={routes.forgetPassword}
                                element={
                                    <ForgetPassWord />
                                }
                            />

                            <Route
                                exact
                                path={routes.resetPassword}
                                element={
                                    <ResetPassword />
                                }
                            />
                        </Routes>
                    </LogoutProvider>
                );
            }}
        </AzureAD>
    );
};

const mapStateToProps = state => {
    return {
        accessToken: state.authentication.accessToken,
        isAuthenticated: state.user.isAuthenticated,
        userProfile: state.user.profile,
        externalUser: state.user.external
    };
};

const mapDispatchToProps = dispatch => {
    return {
        authenticateUser: () => dispatch(actions.authenticateUser()),
        updateUser: data => dispatch(actions.updateUser(data)),
        updateExternal: () => dispatch(actions.externalUser())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(URLPaths);
