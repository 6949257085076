import { Button, Divider, FormLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Tooltip, Typography } from "@mui/material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Layout } from "../../layout"
import { FIXED_DRAWER_WIDTH, FIXED_HEADER_HEIGHT } from "../../../shared/utils/Constants"
import styled from "@emotion/styled"
import { GRAY, LIGHT_GRAY, PRIMARY_COLOR, RED, SECONDARY_COLOR, WHITE } from "../../../theme"
import { Box } from "@mui/system"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from "date-fns/locale"
import { FormControl } from "@mui/material"
import { css } from "@emotion/react"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DataTableAddItem from "../../../shared/components/DataTableAddItem/DataTableAddItem"
import { Modal, Spin, notification } from "antd"
import useRegulatoryContact from "../hooks/useRegulatoryContact"
import useLegalRepresentativeContact from "../hooks/userLegalRepresentative"
import useComercialContact from "../hooks/useComercialContact"
import CarrierDetailsManager from "../CarrierDetailsManager"
import { useLocation, useNavigate } from "react-router-dom"
import AnttStatusService from "../../../services/AnttStatusService"
import parseISO from "date-fns/parseISO"
import { ArrowLeft, Info, Trash2 } from "react-feather"
import CnaeTypeService from "../../../services/CnaeTypeService"
import ReviewRequest from "./ReviewRequest"
import dayjs from "dayjs"
import SolicitationService from "../../../services/SolicitationService"
import { ContactCarreirProfileType } from "../../../shared/utils/enum";


const INIT_OBJ_REQUEST = {
    //validities attributes
    statusANTT: '',
    statusANTTFile: null,
    sassmaqValidity: '',
    sassmaqValidityFile: null,
    paeValidity: '',
    paeValidityFile: null,
    crExercitoValidity: '',
    crExercitoValidityFile: null,
    ctfIbamaValidity: '',
    ctfIbamaValidityFile: null,
    litppValidity: '',
    litppValidityFile: null,
    rctrcValidity: '',
    rctrcValidityFile: null,
    lmgPolicyRCTRC: 0,
    lmgPolicyRCTRCFile: null,
    lmgPolicyRCDA: 0,
    rcdaValidity: '',
    rcdaFile: null,
    civilPoliceLicenseValidity: '',
    civilPoliceLicenseValidityFile: null,
    federalPoliceLicenseValidity: '',
    federalPoliceLicenseValidityFile: null,
    //Carrier infos
    companyName: '',
    statusSocialContractId: 0,
    cnaeTypeId: 0,
    emailSendingInvoice: '',
    socialContractFile: null,
    //Bank infos
    bankCountry: "BR",
    bankKey: 0,
    bankAccount: 0,
    bankAccountAK: 0,
    alternativePayee: 0,
    bankFile: null,
    CNPJ: null
}


const RequestEditCarrier = ({ }) => {

    const location = useLocation();

    const [modal, contextHolderModal] = Modal.useModal();
    const [apiNotification, contextHolder] = notification.useNotification();
    const [requestObj, setRequestObj] = useState(INIT_OBJ_REQUEST);
    const [contacts, setContacts] = useState([]);
    const [statusANTT, setStatusANTT] = useState();
    const [carrierOld, setCarrierOld] = useState();
    const [cnaeOptions, setCnaeOptions] = useState();
    const [isReviewing, setIsReviewing] = useState(false);
    const [toReview, setToReview] = useState(false);
    const [carrierId, setCarrierId] = useState();
    const scrollRef = useRef(null);
    const navigate = useNavigate();

    const [loadSendRequest, setLoadSendRequest] = useState(false);
    const [confirmClick, setConfirmClick] = useState(false);

    const scrollToTop = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0);
        }
    };

    const {
        defaultRegulatoryContactsColumns,
        regulatoryContactsData,
        deletedRegulatoryContact,
        handleDeleteRegulatoryContact,
        handleAddRegulatoryContact,
        handleSaveRegulatoryContact
    } = useRegulatoryContact(contacts, false, apiNotification, modal);


    const {
        defaultLegalRepresentativeContactsColumns,
        legalRepresentativeContactsData,
        deletedRepresentativeContact,
        handleDeleteLegalRepresentativeContact,
        handleAddLegalRepresentativeContact,
        handleSaveLegalRepresentativeContact
    } = useLegalRepresentativeContact(contacts, false, apiNotification, modal);

    const {
        defaultComercialContactsColumns,
        comercialContactsData,
        deletedComercialContact,
        handleDeleteComercialContact,
        handleAddComercialContact,
        handleSaveComercialContact
    } = useComercialContact(contacts, false, apiNotification, modal);


    const handleOptionsANTT = async () => {
        await AnttStatusService.getAnttStatusList().then(response => {
            setStatusANTT(response.data);
        });
    }

    const handleOptionsCNAE = async () => {
        await CnaeTypeService.getCnaeTypeList().then(response => {
            setCnaeOptions(response.data)
        });
    }

    useEffect(() => {
        handleOptionsANTT();
        handleOptionsCNAE();
    }, []);

    const dateNotAllowed = ['0001-01-01T00:00:00', '1900-01-01T00:00:00', '9999-12-31T00:00:00'];

    const getCarrierDetails = useCallback(async id => {
        //setLoading(true);
        setCarrierId(id);
        const data = await CarrierDetailsManager.getCarrierDetails(id);
        if (data) {
            setCarrierOld({
                ...requestObj,
                //validities attributes
                statusANTT: data?.anttStatus?.anttStatusId ? data?.anttStatus?.anttStatusId : 0,
                sassmaqValidity: dateNotAllowed.includes(data.sassmaqValidity) ? null : data.sassmaqValidity,
                paeValidity: dateNotAllowed.includes(data.paeValidity) ? null : data.paeValidity,
                crExercitoValidity: dateNotAllowed.includes(data.crExercitoValidity) ? null : data.crExercitoValidity,
                ctfIbamaValidity: dateNotAllowed.includes(data.ctfIbamaValidity) ? null : data.ctfIbamaValidity,
                litppValidity: dateNotAllowed.includes(data.litppValidity) ? null : data.litppValidity,
                rctrcValidity: dateNotAllowed.includes(data.rctrcValidity) ? null : data.rctrcValidity,
                lmgPolicyRCTRC: data.lmgPolicyRCTRC,
                lmgPolicyRCDA: data.lmgPolicyRCDA,
                rcdaValidity: dateNotAllowed.includes(data.rcdaValidity) ? null : data.rcdaValidity,
                civilPoliceLicenseValidity: dateNotAllowed.includes(data.civilPoliceLicenseValidity) ? null : data.civilPoliceLicenseValidity,
                federalPoliceLicenseValidity: dateNotAllowed.includes(data.federalPoliceLicenseValidity) ? null : data.federalPoliceLicenseValidity,
                //Carrier infos
                companyName: data.companyName,
                statusSocialContractId: data?.statusSocialContract?.statusSocialContractId,
                cnaeTypeId: data?.cnaeType?.cnaeTypeId ? data?.cnaeType?.cnaeTypeId : 0,
                emailSendingInvoice: data.emailSendingInvoice,
                //Bank infos
                bankCountry: data.bankData?.bankCountry,
                bankKey: data.bankData?.bankKey,
                bankAccount: data.bankData?.bankAccount,
                bankAccountAK: data.bankData?.bankAccountAK,
                alternativePayee: data.bankData?.alternativePayee
            });
            setRequestObj({
                ...requestObj,
                //validities attributes
                statusANTT: data?.anttStatus?.anttStatusId ? data?.anttStatus?.anttStatusId : 0,
                sassmaqValidity: dateNotAllowed.includes(data.sassmaqValidity) ? null : data.sassmaqValidity,
                paeValidity: dateNotAllowed.includes(data.paeValidity) ? null : data.paeValidity,
                crExercitoValidity: dateNotAllowed.includes(data.crExercitoValidity) ? null : data.crExercitoValidity,
                ctfIbamaValidity: dateNotAllowed.includes(data.ctfIbamaValidity) ? null : data.ctfIbamaValidity,
                litppValidity: dateNotAllowed.includes(data.litppValidity) ? null : data.litppValidity,
                rctrcValidity: dateNotAllowed.includes(data.rctrcValidity) ? null : data.rctrcValidity,
                lmgPolicyRCTRC: data.lmgPolicyRCTRC,
                lmgPolicyRCDA: data.lmgPolicyRCDA,
                rcdaValidity: dateNotAllowed.includes(data.rcdaValidity) ? null : data.rcdaValidity,
                civilPoliceLicenseValidity: dateNotAllowed.includes(data.civilPoliceLicenseValidity) ? null : data.civilPoliceLicenseValidity,
                federalPoliceLicenseValidity: dateNotAllowed.includes(data.federalPoliceLicenseValidity) ? null : data.federalPoliceLicenseValidity,
                //Carrier infos
                companyName: data.companyName,
                statusSocialContractId: data?.statusSocialContract?.statusSocialContractId,
                cnaeTypeId: data?.cnaeType?.cnaeTypeId ? data?.cnaeType?.cnaeTypeId : 0,
                emailSendingInvoice: data.emailSendingInvoice,
                //Bank infos
                bankCountry: data.bankData?.bankCountry,
                bankKey: data.bankData?.bankKey,
                bankAccount: data.bankData?.bankAccount,
                bankAccountAK: data.bankData?.bankAccountAK,
                alternativePayee: data.bankData?.alternativePayee
            })
        }
        //setOpenDetails(Array(data?.historyLogs?.length).fill(false));
        await CarrierDetailsManager.getUsersByCarrier(id).then((resp) => {
            setContacts(resp);
        })
        scrollToTop();
        //setLoading(false);
    }, []);

    useEffect(() => {
        getCarrierDetails(location.state.carrierId);
    }, [location.state.carrierId, getCarrierDetails]);

    const checkChanges = (e) => {

    }

    const handleAnttStatusChange = (e) => {
        setRequestObj({ ...requestObj, statusANTT: e.target.value });
    };

    const handleValiditySASSMAQChange = (date) => {
        if (date)
            setRequestObj({ ...requestObj, sassmaqValidity: dayjs(date).format() });
    };

    const onClearSASSMAQ = () => {
        setRequestObj({ ...requestObj, sassmaqValidity: null });
    }

    const handleValidityPAEChange = (date) => {
        if (date)
            setRequestObj({ ...requestObj, paeValidity: dayjs(date).format() });
    }

    const onClearPAE = () => {
        setRequestObj({ ...requestObj, paeValidity: null });
    }

    const handleValidityCRExercitoChange = (date) => {
        if (date)
            setRequestObj({ ...requestObj, crExercitoValidity: dayjs(date).format() });
    }

    const onClearCRExercito = () => {
        setRequestObj({ ...requestObj, crExercitoValidity: null });
    }

    const handleValidityCTFIbamaChange = (date) => {
        if (date)
            setRequestObj({ ...requestObj, ctfIbamaValidity: dayjs(date).format() });
    }

    const onClearCTFIbama = () => {
        setRequestObj({ ...requestObj, ctfIbamaValidity: null });
    }

    const handleValidityLITPPChange = (date) => {
        if (date)
            setRequestObj({ ...requestObj, litppValidity: dayjs(date).format() });
    }

    const handleCivilPoliceLicenseValidity = (date) => {
        if (date)
            setRequestObj({ ...requestObj, civilPoliceLicenseValidity: dayjs(date).format() });
    }

    const handleFederalPoliceLicenseValidity = (date) => {
        if (date)
            setRequestObj({ ...requestObj, federalPoliceLicenseValidity: dayjs(date).format() });
    }

    const onClearFederalPoliceLicenseValidity = (date) => {
        if (date)
            setRequestObj({ ...requestObj, federalPoliceLicenseValidity: null });
    }

    const onClearCivilPoliceLicenseValidity = () => {
        setRequestObj({ ...requestObj, civilPoliceLicenseValidity: null });
    }

    const onClearLITPP = () => {
        setRequestObj({ ...requestObj, litppValidity: null });
    }

    const handleValidityRctrcValidityChange = (date) => {
        if (date)
            setRequestObj({ ...requestObj, rctrcValidity: dayjs(date).format() });
    }

    const onClearRctrcValidity = () => {
        setRequestObj({ ...requestObj, rctrcValidity: null });
    }

    const handleLMGRCTRC = (event) => {
        setRequestObj({ ...requestObj, lmgPolicyRCTRC: event.target.value });
    }

    const handleValidityRcdaValidityChange = (date) => {
        if (date)
            setRequestObj({ ...requestObj, rcdaValidity: dayjs(date).format() });
    }

    const onClearRcdaValidity = () => {
        setRequestObj({ ...requestObj, rcdaValidity: null });
    }

    const handleBankKey = (event) => {
        setRequestObj({ ...requestObj, bankKey: event.target.value });
    }

    const handleBankAccount = (event) => {
        setRequestObj({ ...requestObj, bankAccount: event.target.value });
    }

    const handleCompanyName = (event) => {
        setRequestObj({ ...requestObj, companyName: event.target.value });
    }

    const handleOnchangeSocialContract = (event) => {
        setRequestObj({ ...requestObj, statusSocialContractId: event.target.value });
    }

    const handleOnChangeCNAE = (event) => {
        setRequestObj({ ...requestObj, cnaeTypeId: event.target.value });
    }

    const handleOnChangeEmailInvoice = (event) => {
        setRequestObj({ ...requestObj, emailSendingInvoice: event.target.value });
    }

    const handelBankAK = (event) => {
        setRequestObj({ ...requestObj, bankAccountAK: event.target.value });
    }

    const handleAlternativePayee = (e) => {
        setRequestObj({ ...requestObj, alternativePayee: e.target.value });
    }

    const handleFile = (e, type) => {
        const fileValue = e?.target?.files ? Array.from(e.target.files)[0] : null;

        switch (type) {
            case 1:
                setRequestObj({ ...requestObj, statusANTTFile: fileValue });
                break;
            case 2:
                setRequestObj({ ...requestObj, sassmaqValidityFile: fileValue });
                break;
            case 3:
                setRequestObj({ ...requestObj, paeValidityFile: fileValue });
                break;
            case 4:
                setRequestObj({ ...requestObj, crExercitoValidityFile: fileValue });
                break;
            case 5:
                setRequestObj({ ...requestObj, ctfIbamaValidityFile: fileValue });
                break;
            case 6:
                setRequestObj({ ...requestObj, litppValidityFile: fileValue });
                break;
            case 7:
                setRequestObj({ ...requestObj, lmgPolicyRCTRCFile: fileValue });
                break;
            case 8:
                setRequestObj({ ...requestObj, lmgPolicyRCTRCFile: fileValue });
                break;
            case 9:
                setRequestObj({ ...requestObj, rcdaFile: fileValue });
                break;
            case 10:
                setRequestObj({ ...requestObj, bankFile: fileValue });
                break;
            case 11:
                setRequestObj({ ...requestObj, socialContractFile: fileValue })
                break;
            case 12:
                setRequestObj({ ...requestObj, civilPoliceLicenseValidityFile: fileValue })
                break;
            case 13:
                setRequestObj({ ...requestObj, federalPoliceLicenseValidityFile: fileValue })
                break;
            case 14:
                setRequestObj({ ...requestObj, CNPJ: fileValue })
                break;

        }
    }

    const handleLMGRCDA = (e) => {
        setRequestObj({ ...requestObj, lmgPolicyRCDA: e.target.value });
    }

    const goBackToEdit = () => {
        setIsReviewing(false);
        scrollToTop();
    }

    const handleReveiw = () => {

        let msgWarning = new Array();
        if (requestObj.statusANTT && requestObj.statusANTT !== carrierOld.statusANTT) {
            if (requestObj.statusANTTFile === null) {
                msgWarning.push('Documento do Status ANTT não encontrado.\n')
            }
        }

        if (requestObj.sassmaqValidity && requestObj.sassmaqValidity !== carrierOld.sassmaqValidity) {
            if (requestObj.sassmaqValidityFile === null) {
                msgWarning.push('Documento da validade SASSMAQ não encontrado.\n')
            }
        }
        if (requestObj.paeValidity && requestObj.paeValidity !== carrierOld.paeValidity) {
            if (requestObj.paeValidityFile === null) {
                msgWarning.push('Documento da validade P.A.E não encontrado.\n')
            }
        }
        if (requestObj.crExercitoValidity && requestObj.crExercitoValidity !== carrierOld.crExercitoValidity) {
            if (requestObj.crExercitoValidityFile === null) {
                msgWarning.push('Documento da validade CR Exército não encontrado.\n')
            }
        }
        if (requestObj.ctfIbamaValidity && requestObj.ctfIbamaValidity !== carrierOld.ctfIbamaValidity) {
            if (requestObj.ctfIbamaValidityFile === null) {
                msgWarning.push('Documento da validade CTF IBAMA não encontrado.\n')
            }
        }
        if (requestObj.litppValidity && requestObj.litppValidity !== carrierOld.litppValidity) {
            if (requestObj.litppValidityFile === null) {
                msgWarning.push('Documento da validade LITPP não encontrado.\n')
            }
        }
        if (requestObj.rctrcValidity && requestObj.rctrcValidity !== carrierOld.rctrcValidity) {
            if (requestObj.lmgPolicyRCTRCFile === null) {
                msgWarning.push('Documento da validade RCTR-C não encontrado.\n')
            }
        }
        if (requestObj.lmgPolicyRCTRC && requestObj.lmgPolicyRCTRC !== carrierOld.lmgPolicyRCTRC) {
            if (requestObj.lmgPolicyRCTRCFile === null) {
                msgWarning.push('Documento da LMG Apólice RCTR-C não encontrado.\n')
            }
        }

        if ((requestObj.rcdaValidity || requestObj.lmgPolicyRCDA) && requestObj.rcdaValidity !== carrierOld.rcdaValidity || requestObj.lmgPolicyRCDA !== carrierOld.lmgPolicyRCDA) {
            if (requestObj.rcdaFile === null) {
                msgWarning.push('Documento RC-DA não encontrado.\n')
            }
        }

        if (requestObj.civilPoliceLicenseValidity && requestObj.civilPoliceLicenseValidity !== carrierOld.civilPoliceLicenseValidity) {
            if (requestObj.civilPoliceLicenseValidityFile === null) {
                msgWarning.push('Documento da validade Licença Policia Civil não encontrado.\n')
            }
        }

        if (requestObj.federalPoliceLicenseValidity && requestObj.federalPoliceLicenseValidity !== carrierOld.federalPoliceLicenseValidity) {
            if (requestObj.federalPoliceLicenseValidityFile === null) {
                msgWarning.push('Documento da validade Licença Polícia Federal não encontrado.\n')
            }
        }

        if (requestObj.bankCountry !== carrierOld.bankCountry
            || requestObj.bankKey !== carrierOld.bankKey
            || requestObj.bankAccount !== carrierOld.bankAccount
            || requestObj.bankAccountAK !== carrierOld.bankAccountAK
            || requestObj.alternativePayee !== carrierOld.alternativePayee) {
            if (requestObj.bankFile === null) {
                msgWarning.push('Documento Bancario não encontrado.\n')
            }
        }

        if (msgWarning.length > 0) {
            apiNotification['error']({
                message: 'Esta faltando documentos',
                description: <ul>
                    {msgWarning.map(el => <li>{el}</li>)}
                </ul>,
                placement: 'topRight',
                duration: 30
            })
        } else {
            setIsReviewing(true);
            scrollToTop();
        }

    }

    useEffect(() => {
        if (requestObj) {
            let isNotEqual = false;
            for (let attrOld in carrierOld) {
                for (let attr in requestObj) {
                    if (attrOld === attr && !attr.includes('File')) {
                        if (carrierOld[attrOld] !== requestObj[attr]) {
                            isNotEqual = true;
                        }
                    }
                }

                if (isNotEqual) {
                    break;
                }
            }

            if (requestObj.socialContractFile !== null) isNotEqual = true;

            if (deletedComercialContact.length > 0 || deletedRepresentativeContact.length > 0 || deletedRegulatoryContact.length > 0) {
                isNotEqual = true;
            }
            if(requestObj.CNPJ !== null) isNotEqual = true;

            const contactsEdited = [...regulatoryContactsData, ...legalRepresentativeContactsData, ...comercialContactsData]

            if (contactsEdited.filter(element => element.userEmail === '' || element.userEmail === "contato@transportadora.com").length <= 0 && contactsEdited.filter(element => element.edited || element.isNewContact).length > 0) {
                isNotEqual = true
            }

            setToReview(isNotEqual);
        }
    }, [requestObj, deletedComercialContact, deletedRepresentativeContact, deletedRegulatoryContact, regulatoryContactsData, legalRepresentativeContactsData, comercialContactsData]);

    const sendRequest = async (e) => {
        e.preventDefault();
        if (!confirmClick) {

            setConfirmClick(true);
            setLoadSendRequest(true);



            await SolicitationService.sendSolicitation(carrierId).then(async resp => {
                const requestNum = resp.data;
                for (let attrOld in carrierOld) {
                    for (let attr in requestObj) {
                        if (attrOld === attr && !attr.includes('File')) {
                            if (carrierOld[attrOld] !== requestObj[attr]) {
                                if(attr.includes('CNPJ')){
                                    const formData = new FormData();
                                    formData.append('file', requestObj['CNPJ']);
                                    await SolicitationService.sendSolicitationData({
                                        "variable": 'CNPJ',
                                        "oldValue": '',
                                        "newValue":'',
                                        "file": formData
                                    }, requestNum);
                                }else if (attr.includes('bank') || attr.includes('alternativePayee')) {
                                    const formData = new FormData();
                                    formData.append('file', requestObj['bankFile']);
                                    await SolicitationService.sendSolicitationData({
                                        "variable": attr,
                                        "oldValue": carrierOld[attrOld] ? carrierOld[attrOld] : '',
                                        "newValue": requestObj[attr] ? requestObj[attr] : '',
                                        "file": formData
                                    }, requestNum);
                                } else {
                                    const formData = new FormData();

                                    if (attr.includes("rcdaValidity")) {
                                        formData.append('file', requestObj['rcdaFile']);
                                        await SolicitationService.sendSolicitationData({
                                            "variable": attr,
                                            "oldValue": carrierOld[attrOld] ? carrierOld[attrOld] : '',
                                            "newValue": requestObj[attr] ? requestObj[attr] : '',
                                            "file": requestObj["rcdaFile"] ? formData : null
                                        }, requestNum);
                                    } else if (attr.includes("lmgPolicyRCDA")) {
                                        formData.append('file', requestObj['rcdaFile']);
                                        await SolicitationService.sendSolicitationData({
                                            "variable": attr,
                                            "oldValue": carrierOld[attrOld] ? carrierOld[attrOld] : '',
                                            "newValue": requestObj[attr] ? requestObj[attr] : '',
                                            "file": requestObj["rcdaFile"] ? formData : null
                                        }, requestNum);
                                    } else if (attr.includes("lmgPolicyRCTRC")) {
                                        formData.append('file', requestObj['lmgPolicyRCTRCFile']);
                                        await SolicitationService.sendSolicitationData({
                                            "variable": attr,
                                            "oldValue": carrierOld[attrOld] ? carrierOld[attrOld] : '',
                                            "newValue": requestObj[attr] ? requestObj[attr] : '',
                                            "file": requestObj["lmgPolicyRCTRCFile"] ? formData : null
                                        }, requestNum);
                                    } else if (attr.includes("rctrcValidity")) {
                                        formData.append('file', requestObj['lmgPolicyRCTRCFile']);
                                        await SolicitationService.sendSolicitationData({
                                            "variable": attr,
                                            "oldValue": carrierOld[attrOld] ? dayjs(carrierOld[attrOld]).format('YYYY-MM-DD') : '',
                                            "newValue": requestObj[attr] ? dayjs(requestObj[attr]).format('YYYY-MM-DD') : '',
                                            "file": requestObj["lmgPolicyRCTRCFile"] ? formData : null
                                        }, requestNum);
                                    } else if (attr.includes('Validity')) {
                                        formData.append('file', requestObj[attr + 'File']);

                                        await SolicitationService.sendSolicitationData({
                                            "variable": attr,
                                            "oldValue": carrierOld[attrOld] ? dayjs(carrierOld[attrOld]).format('YYYY-MM-DD') : '',
                                            "newValue": requestObj[attr] ? dayjs(requestObj[attr]).format('YYYY-MM-DD') : '',
                                            "file": requestObj[attr + 'File'] ? formData : null
                                        }, requestNum);
                                    } else {
                                        formData.append('file', requestObj[attr + 'File']);

                                        await SolicitationService.sendSolicitationData({
                                            "variable": attr,
                                            "oldValue": carrierOld[attrOld] ? carrierOld[attrOld] : '',
                                            "newValue": requestObj[attr] ? requestObj[attr] : '',
                                            "file": requestObj[attr + 'File'] ? formData : null
                                        }, requestNum);
                                    }
                                }
                            }
                        } else {
                            if (attrOld === attr && carrierOld[attrOld] !== requestObj[attr] && attr.includes('socialContractFile')) {
                                const formData = new FormData();

                                formData.append('file', requestObj['socialContractFile']);
                                await SolicitationService.sendSolicitationData({
                                    "variable": attr,
                                    "oldValue": '--',
                                    "newValue": '--',
                                    "file": requestObj["socialContractFile"] ? formData : null
                                }, requestNum);
                            }
                        }
                    }
                }

                const contactsData = [...regulatoryContactsData, ...legalRepresentativeContactsData, ...comercialContactsData]

                const contactsRequest = contactsData.filter(el => el.edited === true || el.isNewContact === true).map(el => {
                    if (el.isNewContact) {
                        return {
                            "action": "add",
                            "variable": Object.keys(ContactCarreirProfileType).find(key => ContactCarreirProfileType[key] === el.profile.profileType.profileTypeId),
                            "userContactId": 0,
                            "name": el.userName,
                            "email": el.userEmail,
                            "phone": el.phone,
                            "document": el.personalDocument
                        }
                    } else {
                        return {
                            "action": "update",
                            "variable": Object.keys(ContactCarreirProfileType).find(key => ContactCarreirProfileType[key] === el.profile.profileType.profileTypeId),
                            "userContactId": el.id,
                            "name": el.userName,
                            "email": el.userEmail,
                            "phone": el.phone,
                            "document": el.personalDocument
                        }
                    }
                })

                const deletedContacts = [...deletedRegulatoryContact.map(el => {
                    return {
                        "action": "remove",
                        "variable": Object.keys(ContactCarreirProfileType).find(key => ContactCarreirProfileType[key] === el.profile.profileType.profileTypeId),
                        "userContactId": el.id,
                        "name": el.userName,
                        "email": el.userEmail,
                        "phone": el.phone
                    }
                }), ...deletedRepresentativeContact.map(el => {
                    return {
                        "action": "remove",
                        "variable": Object.keys(ContactCarreirProfileType).find(key => ContactCarreirProfileType[key] === el.profile.profileType.profileTypeId),
                        "userContactId": el.id,
                        "name": el.userName,
                        "email": el.userEmail,
                        "document": el.personalDocument
                    }
                }), ...deletedComercialContact.map(el => {
                    return {
                        "action": "remove",
                        "variable": Object.keys(ContactCarreirProfileType).find(key => ContactCarreirProfileType[key] === el.profile.profileType.profileTypeId),
                        "userContactId": el.id,
                        "name": el.userName,
                        "email": el.userEmail,
                    }
                })];

                if (contactsRequest.length > 0 || deletedContacts.length > 0)
                    await SolicitationService.sendContatsSolicitation(carrierId, requestNum, [...contactsRequest, ...deletedContacts])

                setLoadSendRequest(false);
                navigate(-1);
            });
        }
    }

    return (
        <Layout showHeader>
            {contextHolderModal}
            {contextHolder}
            <Spin spinning={loadSendRequest} fullscreen />
            <GridContainerSection>
                <Grid container>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <ArrowLeft
                                    size={25}
                                    color={SECONDARY_COLOR}
                                    onClick={() => navigate(-1)}
                                    className="mr-3 hover-icon"
                                />
                            </Grid>
                            <Grid item>
                                <Typography onClick={() => navigate(-1)} className="hover-icon">
                                    Voltar à tela de detalhe
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <CarrierRequestBox>
                        <Typography variant="h6" sx={css`font-weight: bold;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Solicitar uma edição {isReviewing && "- Revise sua solicitação"}</Typography>
                        <Divider />
                        <Typography variant="subtitle1" sx={css`font-size: 14px;color:${RED};font-family: YaraMax;`}>As alterações realizadas aqui passarão por um fluxo de aprovação na Yara.</Typography>
                        <br />
                        {isReviewing ?
                            <ReviewRequest
                                sendRequest={sendRequest}
                                request={requestObj}
                                carrierConent={carrierOld}
                                statusANTT={statusANTT}
                                cnaeOptions={cnaeOptions}
                                goBackToEdit={goBackToEdit}
                                regulatoryContactsData={regulatoryContactsData.filter(element => element.isNewContact || element.edited)}
                                legalRepresentativeContactsData={legalRepresentativeContactsData.filter(element => element.isNewContact || element.edited)}
                                comercialContactsData={comercialContactsData.filter(element => element.isNewContact || element.edited)}
                                deletedRegulatoryContact={deletedRegulatoryContact}
                                deletedRepresentativeContact={deletedRepresentativeContact}
                                deletedComercialContact={deletedComercialContact}
                            /> : <div>
                                <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Homologação e validades <Typography variant="subtitle2" sx={css`font-weight: bold;display: inline;`}>(Itens que exigem anexo comprobatório)</Typography></Typography>
                                <br />
                                <br />
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Status ANTT</FormLabel>
                                                <Select variant="outlined" sx={css`height: 41px; width: 162px;`} value={requestObj?.statusANTT} onChange={handleAnttStatusChange}>
                                                    <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                    {statusANTT?.map((antt) => (
                                                        <MenuItem key={antt.anttStatusId} value={antt.anttStatusId}>
                                                            {antt.anttStatusName}
                                                        </MenuItem>))}
                                                </Select>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 1)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.statusANTTFile && requestObj.statusANTTFile.name}</Typography>
                                            {requestObj.statusANTTFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 1)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={{ xs: 0, md: 2 }}>
                                            <FormControl component={'fieldset'} sx={css` width: 162px;min-width: 162px;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Validade SASSMAQ</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                                    <DHDatePicker
                                                        value={parseISO(requestObj.sassmaqValidity)}
                                                        onChange={handleValiditySASSMAQChange}
                                                        format="dd/MM/yyyy"
                                                        inputVariant="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        slotProps={{
                                                            field: { clearable: true, onClear: onClearSASSMAQ },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 2)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.sassmaqValidityFile && requestObj.sassmaqValidityFile.name}</Typography>
                                            {requestObj.sassmaqValidityFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 2)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css` width: 162px;min-width: 162px;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}> Validade P.A.E</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                                    <DHDatePicker
                                                        value={requestObj.paeValidity ? parseISO(requestObj.paeValidity) : undefined}
                                                        onChange={handleValidityPAEChange}
                                                        format="dd/MM/yyyy"
                                                        inputVariant="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        slotProps={{
                                                            field: { clearable: true, onClear: onClearPAE },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 3)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.paeValidityFile && requestObj.paeValidityFile.name}</Typography>
                                            {requestObj.paeValidityFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 3)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css` width: 162px;min-width: 162px;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}> Validade CR Exército</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                                    <DHDatePicker
                                                        value={requestObj.crExercitoValidity ? parseISO(requestObj.crExercitoValidity) : undefined}
                                                        onChange={handleValidityCRExercitoChange}
                                                        format="dd/MM/yyyy"
                                                        inputVariant="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        slotProps={{
                                                            field: { clearable: true, onClear: onClearCRExercito },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 4)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.crExercitoValidityFile && requestObj.crExercitoValidityFile.name}</Typography>
                                            {requestObj.crExercitoValidityFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 4)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css` width: 162px;min-width: 162px;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}> Validade CTF IBAMA</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                                    <DHDatePicker
                                                        value={requestObj.ctfIbamaValidity ? parseISO(requestObj.ctfIbamaValidity) : undefined}
                                                        onChange={handleValidityCTFIbamaChange}
                                                        format="dd/MM/yyyy"
                                                        inputVariant="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        slotProps={{
                                                            field: { clearable: true, onClear: onClearCTFIbama },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 5)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.ctfIbamaValidityFile && requestObj.ctfIbamaValidityFile.name}</Typography>
                                            {requestObj.ctfIbamaValidityFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 5)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css` width: 162px;min-width: 162px;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}> Validade LITPP</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                                    <DHDatePicker
                                                        value={requestObj.litppValidity ? parseISO(requestObj.litppValidity) : undefined}
                                                        onChange={handleValidityLITPPChange}
                                                        format="dd/MM/yyyy"
                                                        inputVariant="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        slotProps={{
                                                            field: { clearable: true, onClear: onClearLITPP },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 6)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.litppValidityFile && requestObj.litppValidityFile.name}</Typography>
                                            {requestObj.litppValidityFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 6)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css` width: 162px;min-width: 162px;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Licença Policia Civil</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                                    <DHDatePicker
                                                        value={requestObj.civilPoliceLicenseValidity ? parseISO(requestObj.civilPoliceLicenseValidity) : undefined}
                                                        onChange={handleCivilPoliceLicenseValidity}
                                                        format="dd/MM/yyyy"
                                                        inputVariant="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        slotProps={{
                                                            field: { clearable: true, onClear: onClearCivilPoliceLicenseValidity },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 12)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.civilPoliceLicenseValidityFile && requestObj.civilPoliceLicenseValidityFile.name}</Typography>
                                            {requestObj.civilPoliceLicenseValidityFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 12)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css` width: 162px;min-width: 162px;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Licença Polícia Federal</FormLabel>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                                    <DHDatePicker
                                                        value={requestObj.federalPoliceLicenseValidity ? parseISO(requestObj.federalPoliceLicenseValidity) : undefined}
                                                        onChange={handleFederalPoliceLicenseValidity}
                                                        format="dd/MM/yyyy"
                                                        inputVariant="outlined"
                                                        variant="outlined"
                                                        size="small"
                                                        slotProps={{
                                                            field: { clearable: true, onClear: onClearFederalPoliceLicenseValidity },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 13)} />
                                            </Button>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.federalPoliceLicenseValidityFile && requestObj.federalPoliceLicenseValidityFile.name}</Typography>
                                            {requestObj.federalPoliceLicenseValidityFile &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 12)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <br />
                                <Stack direction={'row'} spacing={2}>
                                    <FormControl component={'fieldset'} sx={css`width: 175px;`}>
                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}> Validade Apólice RCTR-C</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <DHDatePicker
                                                value={requestObj.rctrcValidity ? parseISO(requestObj.rctrcValidity) : undefined}
                                                onChange={handleValidityRctrcValidityChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearRctrcValidity },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                    <FormControl component={'fieldset'}>
                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>LMG Apólice RCTR-C</FormLabel>
                                        <TextField value={requestObj.lmgPolicyRCTRC} onChange={handleLMGRCTRC} type="number" sx={css`&.MuiTextField-root{height: 41px; width: 162px;}`} size="small" InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        }} />
                                    </FormControl>
                                    <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                        <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                        <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 8)} />
                                    </Button>
                                    <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.lmgPolicyRCTRCFile && requestObj.lmgPolicyRCTRCFile.name}</Typography>
                                    {requestObj.lmgPolicyRCTRCFile &&
                                        <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 8)}>
                                            <Trash2 color={RED} />
                                        </IconButton>
                                    }
                                </Stack>
                                <br />
                                <Stack direction={'row'} spacing={2}>
                                    <FormControl component={'fieldset'}>
                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}> Validade Apólice RC-DA</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <DHDatePicker
                                                value={requestObj.rcdaValidity ? parseISO(requestObj.rcdaValidity) : undefined}
                                                onChange={handleValidityRcdaValidityChange}
                                                format="dd/MM/yyyy"
                                                inputVariant="outlined"
                                                variant="outlined"
                                                size="small"
                                                slotProps={{
                                                    field: { clearable: true, onClear: onClearRcdaValidity },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                    <FormControl component={'fieldset'}>
                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>LMG Apólice RC-DA</FormLabel>
                                        <TextField type="number" value={requestObj.lmgPolicyRCDA} onChange={handleLMGRCDA} sx={css`&.MuiTextField-root{height: 41px; width: 162px;}`} size="small" InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        }} />
                                    </FormControl>
                                    <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                        <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                        <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 9)} />
                                    </Button>
                                    <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.rcdaFile && requestObj.rcdaFile.name}</Typography>
                                    {requestObj.rcdaFile &&
                                        <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 9)}>
                                            <Trash2 color={RED} />
                                        </IconButton>
                                    }
                                </Stack>
                                <br />
                                <Divider />
                                <Typography variant="subtitle2" sx={css`margin-top: 5px;color:${GRAY};font-family: YaraMax;`}>Dados Cadastrais</Typography>
                                <br />
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    <Grid item md={5}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Razão social</FormLabel>
                                            <TextField sx={css`&.MuiTextField-root{height: 41px; width: 100%;}`} size="small" value={requestObj.companyName} onChange={handleCompanyName} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}> Status contrato social</FormLabel>
                                            <Select variant="outlined" sx={css`height: 41px; width: 100%;`} value={requestObj.statusSocialContractId} onChange={handleOnchangeSocialContract}>
                                                <MenuItem value={1}>Ativo</MenuItem>
                                                <MenuItem value={2}>Inativo</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={5}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>CNAE</FormLabel>
                                                <Select variant="outlined" sx={css`height: 41px; width: 100%;`} value={requestObj.cnaeTypeId} onChange={handleOnChangeCNAE}>
                                                    <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                    {cnaeOptions?.map((cnae) => (
                                                        <MenuItem key={cnae.cnaeTypeId} value={cnae.cnaeTypeId}>
                                                            {cnae.cnaeTypeName}
                                                        </MenuItem>))}
                                                </Select>
                                            </FormControl>
                                            <FormControl component={'fieldset'} sx={css`width:20%;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Cartão CNPJ</FormLabel>
                                                <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;border-color:${LIGHT_GRAY};`}>
                                                    <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                    <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 14)} />
                                                </Button>
                                            </FormControl>
                                            <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.CNPJ && requestObj.CNPJ.name}</Typography>
                                            {requestObj.CNPJ &&
                                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 14)}>
                                                    <Trash2 color={RED} />
                                                </IconButton>
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={5}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>E-mail para envio da NF-e Yara (Material)</FormLabel>
                                            <TextField sx={css`&.MuiTextField-root{height: 41px; width: 100%;}`} size="small" value={requestObj.emailSendingInvoice} onChange={handleOnChangeEmailInvoice} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={5}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>
                                                <Stack direction={'row'} spacing={1}>
                                                    <div>
                                                        Contrato social
                                                    </div>
                                                    <Tooltip title={'Botão destinado para enviar o contrato social.'}>
                                                        <Info size={16} color={SECONDARY_COLOR} />
                                                    </Tooltip>
                                                </Stack>
                                            </FormLabel>
                                            <Stack direction={'row'} spacing={1}>
                                                <Button component="label" variant="outlined" sx={css`height: 41px;width: 99px;border-color:${LIGHT_GRAY};`}>
                                                    <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                    <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 11)} />
                                                </Button>
                                                <Typography sx={css`margin-top: 10px !important;font-size:12px;overflow: hidden;`} variant="caption">{requestObj.socialContractFile && requestObj.socialContractFile.name}</Typography>
                                                {requestObj.socialContractFile &&
                                                    <IconButton onClick={() => handleFile({}, 11)}>
                                                        <Trash2 color={RED} />
                                                    </IconButton>
                                                }
                                            </Stack>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                <Divider />
                                <Typography variant="subtitle2" sx={css`margin-top: 5px;color:${GRAY};font-family: YaraMax;`}>Contato regulatório</Typography>
                                <br />
                                <DataTableAddItem
                                    titleButton="Adicionar Contato regulátorio"
                                    columns={defaultRegulatoryContactsColumns}
                                    dataSource={regulatoryContactsData}
                                    handleAdd={handleAddRegulatoryContact}
                                    handleSave={handleSaveRegulatoryContact}
                                    handleDelete={handleDeleteRegulatoryContact}
                                />
                                <br />
                                <Divider />
                                <Typography variant="subtitle2" sx={css`margin-top: 5px;color:${GRAY};font-family: YaraMax;`}>Representante legal</Typography>
                                <br />
                                <DataTableAddItem
                                    titleButton="Adicionar Representante legal"
                                    columns={defaultLegalRepresentativeContactsColumns}
                                    dataSource={legalRepresentativeContactsData}
                                    handleAdd={handleAddLegalRepresentativeContact}
                                    handleSave={handleSaveLegalRepresentativeContact}
                                    handleDelete={handleDeleteLegalRepresentativeContact}
                                />
                                <br />
                                <Divider />
                                <Typography variant="subtitle2" sx={css`margin-top: 5px;color:${GRAY};font-family: YaraMax;`}>Contato comercial</Typography>
                                <br />
                                <DataTableAddItem
                                    titleButton="Adicionar Contato comercial"
                                    columns={defaultComercialContactsColumns}
                                    dataSource={comercialContactsData}
                                    handleAdd={handleAddComercialContact}
                                    handleSave={handleSaveComercialContact}
                                    handleDelete={handleDeleteComercialContact}
                                />
                                <br />
                                <Divider />
                                <Typography variant="subtitle2" sx={css`margin-top: 5px;color:${GRAY};font-family: YaraMax;`}>Dados bancários</Typography>
                                <br />
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    <Grid item md={3}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>País</FormLabel>
                                            <Select variant="outlined" sx={css`height: 41px; width: 100%;`} value={requestObj.bankCountry} onChange={e => setRequestObj({ ...requestObj, bankCountry: e.target.value })}>
                                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                                <MenuItem value="BR" sx={css`font-size: 13.5;`}>BR</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Bank key</FormLabel>
                                            <TextField type="number" sx={css`&.MuiTextField-root{height: 41px; width: 100%;}`} size="small" value={requestObj.bankKey} onChange={handleBankKey} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Bank account</FormLabel>
                                            <TextField sx={css`&.MuiTextField-root{height: 41px; width: 100%;}`} size="small" value={requestObj.bankAccount} onChange={handleBankAccount} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3}>
                                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>AK</FormLabel>
                                            <TextField type="number" sx={css`&.MuiTextField-root{height: 41px; width: 100%;}`} size="small" value={requestObj.bankAccountAK} onChange={handelBankAK} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={requestObj.bankFile ? 5 : 3.85}>
                                        <Stack direction={'row'} spacing={2}>
                                            <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Alternative Payee</FormLabel>
                                                <TextField type="number" sx={css`&.MuiTextField-root{height: 41px; width: 100%;}`} size="small" value={requestObj.alternativePayee} onChange={handleAlternativePayee} />
                                            </FormControl>
                                            <Stack direction={'row'} spacing={1}>
                                                <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};`}>
                                                    <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                                    <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e, 10)} />
                                                </Button>
                                                <Tooltip title={'Todos os comprovantes devem obedecer as premissas de constar o CNPJ + razão social e as informações: nome do banco, número da agência e número da conta corrente. São aceitos cópia de cheque, cabeçalho do extrato bancário, ou carta timbrada do banco com assinatura do gerente.'}>
                                                    <Info size={16} color={SECONDARY_COLOR} />
                                                </Tooltip>
                                            </Stack >

                                            <Stack direction={'row'} spacing={2}>
                                                <Typography sx={css`margin-top:30px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{requestObj.bankFile && requestObj.bankFile.name}</Typography>
                                                {requestObj.bankFile &&
                                                    <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile({}, 10)}>
                                                        <Trash2 color={RED} />
                                                    </IconButton>
                                                }
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <br />
                                <Divider />
                                <br />
                                <CancelButton onClick={() => navigate(-1)}>
                                    CANCELAR
                                </CancelButton>
                                <ConfirmButton onClick={handleReveiw} disabled={!toReview}>
                                    Prosseguir e revisar
                                </ConfirmButton>
                            </div>
                        }
                    </CarrierRequestBox>
                </Grid>
            </GridContainerSection>
        </Layout>
    )
}

const GridContainerSection = styled(Grid)`
    padding: 2rem 2rem 2rem ${FIXED_DRAWER_WIDTH};
    min-height: calc(100vh - ${FIXED_HEADER_HEIGHT});
    background-color: ${LIGHT_GRAY};

    @media only screen and (max-width: 768px){
        padding: 2rem 2px;
    }
`

const CarrierRequestBox = styled(Box)`
    background-color: WHITE;
    width: 100%;
    border-radius: 10px;
    margin-left: 6px;
    margin-top: 6px;
    padding: 20px;
    position: relative;
`

const DHDatePicker = styled(DatePicker)`
    background-color: #FFFFFF;
    & .MuiFormControl-root{
        width: 100%;
        min-width: 140px;
    }

    & .MuiInputBase-input {
        font-size: 12px;
        font-family: YaraMax;
        cursor: pointer;
        height: 20px;
        width: 100%;
        max-width: 162px;
        padding: 10.5px 14px;
        margin-right: -50px;
    }
    & .MuiInput-underline:before {
        display: none;
    }
    & .MuiInput-underline:after {
        display: none;
    }
    &:hover{
        border-color: ${SECONDARY_COLOR};
    }
    & .MuiInputLabel-formControl {
        top: -5px;
    }

    & .MuiOutlinedInput-adornedEnd {
        padding-right: 7px
    }
`
export const CancelButton = styled(Button)`
    background-color: white;
    margin: 20;
    color: red;
    border-radius: 10px;
    height: 56px;
    width: 150px;
    font-family: YaraMax;
    &:hover {
        background-color: red;
        color: white
    }
`

const RollbackButton = styled(Button)`
    background-color: white;
    margin: 20;
    color: ${PRIMARY_COLOR};
    border-radius: 10px;
    height: 56px;
    width: 150px;
    font-family: YaraMax;
    &:hover {
        background-color: ${PRIMARY_COLOR};
        color: white
    }
`

export const ConfirmButton = styled(Button)`
    background-color: ${PRIMARY_COLOR};
    color: white;
    border-radius: 10px;
    width: 269px;
    height: 56px;
    right:20px;
    position: absolute;
    font-family: YaraMax;
    &.Mui-disabled {
      color: ${WHITE};
      background-color: ${GRAY}
    }
    &:hover: {
      color: ${SECONDARY_COLOR}
    }
`

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default RequestEditCarrier
